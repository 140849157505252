import React from 'react';
import { Gender, IPetAndConsultationFormData } from '../../../../models';
import { capitalizeFirstWord } from '../../../../utils/utils';
import { PiGenderFemaleBold, PiGenderMaleBold } from 'react-icons/pi';
import { Row, Col } from 'react-bootstrap';


interface PetDetailsProps {
    isEditMode: boolean;
    consultationInFormData: IPetAndConsultationFormData;
    setConsultationInFormData: React.Dispatch<React.SetStateAction<IPetAndConsultationFormData>>;
}

export const PetDetails: React.FC<PetDetailsProps> = ({ isEditMode, consultationInFormData, setConsultationInFormData }) => {

    const renderField = (nameOfElement: string, value: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => {
        return isEditMode ? (
            <input className='inputFieldTextEditConsultation' name={nameOfElement} type="text" value={value} onChange={onChange} />
        ) : (
            <p>{value ? capitalizeFirstWord(value) : 'Not provided'}</p>
        );
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setConsultationInFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div>
            <h3 className=" p-3 ml-3">Pet</h3>
            <p className=" mx-4  p-3  tips-content text-secondary bg-accentSoft small">
                Understanding the pet's medical history, and
                health condition is essential for accurate diagnosis. Any
                unique characteristics, such as medical conditions or
                previous injuries, can significantly impact the
                interpretation of the data.
            </p>
            <hr className="mx-3" />
            <Row className="p-0 m-0">
                <Col className="mx-4  p-3">
                    <h4>Animal</h4>
                    {renderField("animal", consultationInFormData.animal, handleChange)}
                </Col>
                <Col className="mx-4  p-3">
                    <h4>
                        Breed of {consultationInFormData.animal}
                    </h4>
                    {renderField("breed", consultationInFormData.breed, handleChange)}
                </Col>
            </Row>

            <Row className="p-0 m-0">
                <Col className="mx-4  p-3">
                    <h4>Gender</h4>
                    {isEditMode ? (
                        <select
                            className='selectEditConsultation'
                            name="gender"
                            value={consultationInFormData.gender}
                            onChange={handleChange}
                        >
                            <option value={Gender.M}>Male &nbsp;&nbsp;-&nbsp;&nbsp;♂</option>
                            <option value={Gender.F}>Female&nbsp;&nbsp;-&nbsp;&nbsp;♀</option>
                        </select>
                    ) : (
                        <p>
                            {consultationInFormData?.gender ? (
                                consultationInFormData.gender === Gender.M ? (
                                    <span>
                                        Male&nbsp;&nbsp;-&nbsp;&nbsp;
                                        <PiGenderMaleBold />
                                    </span>
                                ) : (
                                    <span>
                                        Female&nbsp;&nbsp;-&nbsp;&nbsp;
                                        <PiGenderFemaleBold />
                                    </span>
                                )
                            ) : (
                                'Not provided'
                            )}
                        </p>
                    )}
                </Col>
                <Col className="mx-4  p-3">
                    <h4>Weight</h4>
                    {renderField("weight", consultationInFormData.weight, handleChange)}
                </Col>
            </Row>

            <Row className="p-0 m-0">
                <Col className="mx-4  p-3">
                    <h4>Age</h4>
                    {renderField("age", consultationInFormData.age, handleChange)}
                </Col>


                <Col className="mx-4  p-3">
                    <h4>Sterilized</h4>
                    {isEditMode ?
                        (
                            <select
                                className='textFieldForm p-1'
                                name="sterilzation"
                                value={consultationInFormData.sterilzation}
                                onChange={handleChange}
                            >
                                <option value={""}>Not sterilized</option>
                                {consultationInFormData.gender == Gender.M ?
                                    (
                                        <>
                                            <option value={"Surgical Castration (Neutering)"}>Surgical Castration (Neutering)</option>
                                            <option value={"Vasectomy"}>Vasectomy</option>
                                            <option value={"Chemical Vasectomy (Non-surgical)"}>Chemical Vasectomy (Non-surgical)</option>
                                        </>
                                    ) :
                                    (
                                        <>
                                            <option value={"Surgical Spaying"}>Surgical Spaying</option>
                                            <option value={"Chemical Sterilization"}>Chemical Sterilization</option>
                                            <option value={"Hysterectomy"}>Hysterectomy</option>
                                            <option value={"Tubal Ligation"}>Tubal Ligation</option>
                                            <option value={"Ovariectomy"}>Ovariectomy</option>
                                        </>
                                    )}

                            </select>



                        ) :
                        (
                            <p>{consultationInFormData.sterilzation ? capitalizeFirstWord(consultationInFormData.sterilzation) : 'Not sterilized'}</p>
                        )}
                </Col>
            </Row>

            <Row className="p-0 m-0">
                <Col className="mx-4  p-3">
                    <h4>Anamnesis</h4>
                    {isEditMode ? (
                        <div>
                            <br />
                            <textarea
                                className='inputFieldTextAreaEditConsultation'
                                rows={4}
                                name="medicalHistory"
                                value={consultationInFormData.medicalHistory ? consultationInFormData.medicalHistory : ''}
                                onChange={handleChange}
                            />
                        </div>
                    ) : <p>{consultationInFormData.medicalHistory ? capitalizeFirstWord(consultationInFormData.medicalHistory) : 'Not provided'}</p>}
                </Col>
            </Row>
        </div>
    );
};

export default PetDetails;



