import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import { Loader } from '../../common/loader/Loader';
import { logInRequest } from '../../../services/API/AuthService';
import { useNavigate } from 'react-router-dom';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import './AuthPage.scss';

import logo from '../../../assets/imgs/logo.png';
import picture0 from '../../../assets/imgs/loginWallpapers/login_wallpaper_0.jpg';
import picture1 from '../../../assets/imgs/loginWallpapers/login_wallpaper_1.jpg';
import picture2 from '../../../assets/imgs/loginWallpapers/login_wallpaper_2.jpg';
import picture3 from '../../../assets/imgs/loginWallpapers/login_wallpaper_3.jpg';
import picture4 from '../../../assets/imgs/loginWallpapers/login_wallpaper_4.jpg';
import picture5 from '../../../assets/imgs/loginWallpapers/login_wallpaper_5.jpg';
import picture6 from '../../../assets/imgs/loginWallpapers/login_wallpaper_6.jpg';
import picture7 from '../../../assets/imgs/loginWallpapers/login_wallpaper_7.jpg';
import picture8 from '../../../assets/imgs/loginWallpapers/login_wallpaper_8.jpg';
import picture9 from '../../../assets/imgs/loginWallpapers/login_wallpaper_9.jpg';
import picture10 from '../../../assets/imgs/loginWallpapers/login_wallpaper_10.jpg';

const picturePaths = [
  picture0,
  picture1,
  picture2,
  picture3,
  picture4,
  picture5,
  picture6,
  picture7,
  picture8,
  picture9,
  picture10,
];

const firebaseConfig = {
  apiKey: 'AIzaSyDychpZUQgfDD7218h7OJmrh9bayuO71ac',
  authDomain: 'vet-assist-test-env.firebaseapp.com',
  projectId: 'vet-assist-test-env',
  storageBucket: 'vet-assist-test-env.appspot.com',
  messagingSenderId: '202408117255',
  appId: '1:202408117255:web:dac96cc442aac7be8e96a2',
  measurementId: 'G-QDXNJR43V9',
};

firebase.initializeApp(firebaseConfig);

const uiConfig = {
  signInFlow: 'popup',
  callbacks: {
    // Specify a custom callback to prevent automatic redirect
    signInSuccessWithAuthResult: () => {
      return false; // Prevent automatic redirect
    },
  },
  signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
};

const pwdCheck = (pwd: string) => {
  const lowerCaseRegex = /[a-z]/;

  const upperCaseRegex = /[A-Z]/;

  const digitRegex = /[0-9]/;

  const isLengthValid = pwd.length >= 8;

  return (
    lowerCaseRegex.test(pwd) &&
    upperCaseRegex.test(pwd) &&
    digitRegex.test(pwd) &&
    isLengthValid
  );
};

const nameCheck = (name: string) => {
  const validNameRegex = /^[A-Za-z ]{5,}$/;
  return validNameRegex.test(name);
};

export const AuthPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPicture, setCurrentPicture] = useState(picturePaths[0]);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let timer: NodeJS.Timeout | null = null; // Initialize a timer variable
  const [usernameError, setUserNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handlePasswordInput = (password: HTMLInputElement) => {
    const passwordInput = password.value;
    if (!pwdCheck(passwordInput)) {
      setPasswordError(
        'Your password should be atleast 8 characters long, containing capital letters, lowercase letters and digits'
      );
      password.style.borderColor = 'red';
      return true;
    } else {
      setPasswordError('');
      password.style.borderColor = 'lightgreen';
      return false;
    }
  };

  const handleUsernameInput = (name: HTMLInputElement) => {
    const nameInput = name.value;
    if (!nameCheck(nameInput)) {
      setUserNameError(
        'Please enter your first and last name, separated by a space'
      );
      name.style.borderColor = 'red';
      return true;
    } else {
      setUserNameError('');
      name.style.borderColor = 'lightgreen';
      return false;
    }
  };

  const clearInputs = () => {
    setUserNameError('');
    setPasswordError('');
  };

  const redirectToHomePage = () => {
    navigate('/');
    console.log('Go to home');
  };

  const redirectToLinkingPage = () => {
    navigate('/link');
    console.log('Go to linked');
  };

  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    if (document.getElementById('firebaseui-auth-container') != null) {
      ui.start('#firebaseui-auth-container', uiConfig);
    }
    const handleAuthStateChanged = async (user: firebase.User | null) => {
      if (timer) {
        clearTimeout(timer);
      }

      // The timer is only used to debounce the requests, why? -> otherwise 2 or even more requests could be send at once.
      // This solution avoids this problem.
      timer = setTimeout(async () => {
        if (user) {
          try {
            setIsLoading(true);
            const response = await logInRequest(await user.getIdToken());
            if (response.ok) {
              redirectToHomePage();
            }
            if (!response.ok) {
              console.error('Error logging in: ' + response.statusText);
              redirectToLinkingPage();
            }
          } catch (error) {
            console.error('Error generating custom claims:', error);
            // Handle the error (e.g., show an error message to the user)
            // You can also add a return statement here to prevent further execution
          } finally {
            setIsLoading(false);
          }
        }
      }, 500); // Adjust the delay as needed
    };

    const getRandomIndex = () => {
      let randomIndex = Math.floor(Math.random() * picturePaths.length);
      while (randomIndex === currentIndex) {
        randomIndex = Math.floor(Math.random() * picturePaths.length);
      }
      setCurrentIndex(randomIndex);
      return randomIndex;
    };

    const interval = setInterval(() => {
      setTimeout(() => {
        setCurrentPicture(picturePaths[getRandomIndex()]);
      }, 1000);
    }, 8000);
    const authStateChangedUnsubscribe = firebase
      .auth()
      .onAuthStateChanged(handleAuthStateChanged);

    // Clean up the event listener when the component unmounts
    return () => {
      authStateChangedUnsubscribe();
      clearInterval(interval);
    };
  }, []);

  const handleInput = () => {
    const name = document.getElementById('ui-sign-in-name-input');
    const pwd = document.getElementById('ui-sign-in-new-password-input');
    const cancel = document.getElementsByClassName(
      'firebaseui-id-secondary-link'
    );

    const nameInput = name as HTMLInputElement;
    const pwdInput = pwd as HTMLInputElement;

    const save = document.getElementsByClassName('firebaseui-id-submit');
    const saveButton = save[0] as HTMLButtonElement;
    if (saveButton && saveButton.textContent === 'Save') {
      const cancelButton = cancel[0] as HTMLButtonElement;
      cancelButton.addEventListener('click', () => clearInputs());
      const pwd = handlePasswordInput(pwdInput);
      const username = handleUsernameInput(nameInput);
      if (!pwd && !username) {
        saveButton.disabled = false;
      } else {
        saveButton.disabled = true;
      }
    }
  };

  useEffect(() => {
    handleInput();
    const passwordCheckInterval = setInterval(() => {
      handleInput();
    }, 1000);

    return () => {
      clearInterval(passwordCheckInterval);
    };
  }, []);

  return (
    <div>
      {isLoading ? (
        <div style={{ height: '40rem' }}>
          <Loader text="Hold tight, we're signing you in..." />
        </div>
      ) : (
        <div className="auth-page-container">
          <div className="form-container">
            <div className="d-flex justify-content-center">
              <img className="logoAuth pr-2" src={logo} alt="vet assist logo" />
            </div>
            <div className="d-flex justify-content-center">
              <div id="firebaseui-auth-container">
                <p
                  id="usernameError"
                  className="error-message"
                  style={{ fontSize: '13px' }}
                >
                  {usernameError}
                </p>
                <p
                  id="passwordError"
                  className="error-message"
                  style={{ fontSize: '13px' }}
                >
                  {passwordError}
                </p>
              </div>
            </div>
          </div>
          <div className={`image-container `}>
            <img id="wallpaper" src={currentPicture} alt="Login Wallpaper" />
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthPage;
