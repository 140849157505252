import React, { ChangeEvent, useState } from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import { IoCloseOutline } from 'react-icons/io5';
import { AiOutlineUserAdd } from 'react-icons/ai';
import './AddUserModal.scss';

interface AddUserModalProps {
  show: boolean;
  onCancel: () => void;
  onInviteUser: (newUser: { email: string; role: string }) => void;
}

export const AddUserModal: React.FC<AddUserModalProps> = ({
  show,
  onCancel,
  onInviteUser,
}) => {
  const [newUser, setNewUser] = useState({ email: '', role: 'user' });
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRoleChange = (e: ChangeEvent<{ value: string }>) => {
    setNewUser({
      ...newUser,
      role: e.target.value,
    });
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;

    setNewUser({
      ...newUser,
      email: emailValue,
    });
    setIsValidEmail(validateEmail(emailValue));
  };

  const handleInviteUser = () => {
    onInviteUser(newUser);
    // Reset form fields and hide the popup
    setNewUser({ email: '', role: 'user' });
    setIsValidEmail(true);
    onCancel();
  };

  return (
    <Modal show={show} onHide={onCancel} centered className="addUserModal mx-4">
      <Modal.Header className="pb-0 mb-0">
        <Row className="w-100 m-auto">
          <Col md={12} className="d-flex justify-content-between">
            <AiOutlineUserAdd className="text-accent h1"></AiOutlineUserAdd>
            <button onClick={onCancel} className="text-secondary ">
              <IoCloseOutline className="text-secondary"></IoCloseOutline>
            </button>
          </Col>

          <Col md={12} className=" pt-3">
            <h2 className="fw-600">
              Add a new {newUser.role === 'admin' ? 'admin' : 'user'} to this
              organization
            </h2>
            <p className="text-secondary">
              Whenever the {newUser.role === 'admin' ? 'admin' : 'user'} is
              invited he/she will receive an invitation email containing an
              invitation code. This <b>invitation code is</b> unique and{' '}
              <b>non-transferable</b>, meaning only the invited email can redeem
              this invitiation code.
            </p>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className="mx-3">
        <Form>
          <Row>
            <Col>
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                value={newUser.email}
                onChange={handleEmailChange}
                isInvalid={!isValidEmail}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Role:</Form.Label>
              <Form.Control
                as="select"
                value={newUser.role}
                onChange={handleRoleChange}
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </Form.Control>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancelAddUser" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!isValidEmail}
          className="inviteAddUser fw-650"
          onClick={handleInviteUser}
        >
          Invite {newUser.role === 'admin' ? 'admin' : 'user'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddUserModal;
