import {Consultation, Gender,Pet} from "./"

export interface User {
  id: string;
  role: string;
  email: string;
  status: string;
  totalCostUSDDiagnosticCurrentMonthYear:number;
  totalDiagnosticsCurrentMonthYear:number;
}

export interface InformationRecordUserValue  {
  totalCostUSDDiagnosticCurrentMonthYear:number;
  totalDiagnosticsCurrentMonthYear:number;
}

export type InformationRecordsAboutUsers = Record<string, InformationRecordUserValue>

export function initializeDictFromUsers(users:User[]): InformationRecordsAboutUsers {
  const initializedDict: InformationRecordsAboutUsers = {};

  users.forEach((user) => {
    const lowercaseEmail = user.email.toLowerCase();
    initializedDict[lowercaseEmail] = {
      totalCostUSDDiagnosticCurrentMonthYear: 0,
      totalDiagnosticsCurrentMonthYear: 0,
    };
  });

  return initializedDict;
}



export type UsagePerHourElement = {
  userEmail: string;
  userId: string;
  totalCostUSDDiagnostic: number;
};


export type DayUsageFields = {
  usageMapPerHour: Record<string,  UsagePerHourElement[] >;
  userEmailsGeneratedDiagnose: string[];
  userIdsGeneratedDiagnose: string[];
};



export type DayUsagesMap =  Record<string,  DayUsageFields > ;


export interface IPetAndConsultationFormData {
  animal: string;
  breed: string;
  gender: Gender;
  age: string;
  weight: string;
  medicalHistory:string;
  symptoms: string;
  analyses: string[];
  sterilzation: string;
};

export function initializePetAndConsultationFormData(): IPetAndConsultationFormData {
  return {
    animal: '',
    breed: '',
    gender: Gender.M,
    age: '',
    weight: '',
    medicalHistory: '',
    symptoms: '',
    analyses: [''],
    sterilzation: "",
  };
}

export function initializePetAndConsultationFormDataFillWithConsultation(consultation:Consultation): IPetAndConsultationFormData {
  const pet = consultation.getPet();

  return {
    animal: pet.getAnimalType,
    breed: pet.getBreed,
    gender: pet.getGender,
    age: pet.getAge,
    weight: pet.getWeight,
    medicalHistory: pet.getMedicalHistory,
    sterilzation: pet.getSterilzation,
    symptoms: consultation.getSymptoms().join(', '),
    analyses: consultation.getAnalyses(),
  };
}
