import { DayUsagesMap, User, InformationRecordsAboutUsers } from '../../models';

export function getDaysInMonth(monthYear: string): number {
  const [month, year] = monthYear.split('-').map(Number);

  // Check if it's a leap year
  const isLeapYear = (year: number) =>
    (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

  // Array of days in each month
  const daysInMonth = [
    31, // January
    isLeapYear(year) ? 29 : 28, // February (leap year check)
    31, // March
    30, // April
    31, // May
    30, // June
    31, // July
    31, // August
    30, // September
    31, // October
    30, // November
    31, // December
  ];

  return daysInMonth[month - 1]; // Array is 0-indexed, months are 1-indexed
}

export function getDaysInMonthInArray(monthYear: string): number[] {
  let arr = [];
  for (let index = 1; index <= getDaysInMonth(monthYear); index++) {
    arr.push(index);
  }
  return arr;
}

export function sortUsers(users: User[]): void {
  users.sort((a: User, b: User) => {
    const statusOrder: { [key: string]: number } = {
      active: 0,
      invited: 1,
      disabled: 2,
    };

    const roleOrder: { [key: string]: number } = {
      admin: 0,
      user: 1,
    };

    // First, sort by status
    const statusDiff = statusOrder[a.status] - statusOrder[b.status];
    if (statusDiff !== 0) {
      return statusDiff;
    }

    // If status is the same, sort by totalCostUSDDiagnosticCurrentMonthYear in descending order
    const costDiff =
      b.totalCostUSDDiagnosticCurrentMonthYear -
      a.totalCostUSDDiagnosticCurrentMonthYear;
    if (costDiff !== 0) {
      return costDiff;
    }

    // If status is the same, sort by role
    return roleOrder[a.role] - roleOrder[b.role];
  });
}

export function assignTotalCostPerUser(
  users: User[],
  totalUsagePerUser: InformationRecordsAboutUsers
): void {
  for (let currentUser of users) {
    const userEmail = currentUser.email.toLocaleLowerCase();
    if (userEmail in totalUsagePerUser) {
      currentUser.totalCostUSDDiagnosticCurrentMonthYear = totalUsagePerUser[userEmail].totalCostUSDDiagnosticCurrentMonthYear;
      currentUser.totalDiagnosticsCurrentMonthYear = totalUsagePerUser[userEmail].totalDiagnosticsCurrentMonthYear;
    } else {
      currentUser.totalCostUSDDiagnosticCurrentMonthYear = 0;
      currentUser.totalDiagnosticsCurrentMonthYear = 0;
    }
  }
}

export function sumUpTotalCostCurrentMonthPerUserEmail(
  totalUsagePerUser: InformationRecordsAboutUsers,
  selectedMonthYear: string,
  daysUsage: DayUsagesMap
): void {
  
  for (let i = 1; i <= getDaysInMonth(selectedMonthYear); i++) {
    const dayUsage = daysUsage[i];
    if (dayUsage !== undefined && Object.keys(totalUsagePerUser).length!==0) {
      Object.entries(dayUsage.usageMapPerHour).map(
        ([hour, hourArrayOfUsages]) => {
          for (let index = 0; index < hourArrayOfUsages.length; index++) {
            const usagePerHourElement = hourArrayOfUsages[index];
            const userEmail = usagePerHourElement.userEmail.toLocaleLowerCase();
            if (userEmail in totalUsagePerUser) {
              const currentCost =totalUsagePerUser[userEmail].totalCostUSDDiagnosticCurrentMonthYear;
              const currentDiagnosticCount =totalUsagePerUser[userEmail].totalDiagnosticsCurrentMonthYear;
              totalUsagePerUser[userEmail].totalCostUSDDiagnosticCurrentMonthYear = currentCost + usagePerHourElement.totalCostUSDDiagnostic;
              totalUsagePerUser[userEmail].totalDiagnosticsCurrentMonthYear = currentDiagnosticCount + 1;
            } else {
              totalUsagePerUser[userEmail].totalCostUSDDiagnosticCurrentMonthYear = usagePerHourElement.totalCostUSDDiagnostic;
              totalUsagePerUser[userEmail].totalDiagnosticsCurrentMonthYear = 1
            }
          }
        }
      );
    }
  }
}
