import React from 'react';
import { SliceTooltipProps } from '@nivo/line';

interface CustomTooltipProps extends SliceTooltipProps {
  monthYear: string;
}

export const CustomTooltipMonth: React.FC<CustomTooltipProps> = ({
  slice,
  monthYear,
}) => {
  function handleYAxis(serieId: any, point: any): React.ReactNode {
    if (serieId === 'diagnostics') {
      return (
        <div key={point.id}>
          (y-axis) <b>Generated Diagnostics:</b> {point.data.y}
        </div>
      );
    } else if (serieId === 'totalCost') {
      return (
        <div key={point.id}>
          (x-axis) <b>Day of Month:</b> {`${point.data.x}-${monthYear}`}
          <hr />
          (y-axis) <b>Total cost for this day:</b> $
          {parseFloat(point.data.y).toFixed(3)}
        </div>
      );
    }
    return null;
  }

  return (
    <div className="customToolTip">
      <div>
        {slice.points.map((point) => handleYAxis(point.serieId, point))}
      </div>
    </div>
  );
};

export default CustomTooltipMonth;
