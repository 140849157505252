import React from 'react';
import { truncate } from '../../../../utils/utils';
import { FiRotateCcw } from 'react-icons/fi';
import { Consultation, Diagnostic } from '../../../../models';

interface InterpretationVersionsProps {
    returnedConsultation: Consultation;
    currentDiagnose: Diagnostic | null | undefined;
    setCurrentDiagnose: React.Dispatch<React.SetStateAction<Diagnostic | null | undefined>>
    handleRetryInterpretationSubmit: (e: React.FormEvent) => Promise<void>;
}


export const InterpretationVersions: React.FC<InterpretationVersionsProps> = ({
    returnedConsultation,
    currentDiagnose,
    setCurrentDiagnose,
    handleRetryInterpretationSubmit,

}
) => {

    return (
        <div>

            <h2 className="text-center">All versions</h2>
            <div className="d-flex justify-content-center">
                <div className="versionBox bg-accentSoft">
                    {returnedConsultation
                        ? returnedConsultation
                            ?.getSortedDiagnostics()
                            .map((diagnostic, index) => (
                                <div className="d-flex justify-content-center">
                                    <button
                                        className={`versionBtn my-1 ${currentDiagnose?.getCreated() ===
                                            diagnostic.getCreated()
                                            ? ' selectedDiagnostic '
                                            : ''
                                            }`}
                                        onClick={() => {
                                            setCurrentDiagnose(diagnostic);
                                        }}
                                    >
                                        <h3 className="text-left">
                                            {truncate(
                                                `${index + 1}. ${diagnostic
                                                    ?.getTitleChat()
                                                    ?.getAnswer()}`
                                            )}
                                        </h3>
                                        <hr />
                                        <p>
                                            {diagnostic
                                                ? diagnostic.getDate()
                                                : 'Date unavailable'}
                                        </p>
                                    </button>
                                </div>
                            ))
                        : ''}
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <button className="regenerateBtn" onClick={handleRetryInterpretationSubmit}>
                    <FiRotateCcw className="roundArrow" />
                    {'   '}Retry Interpretation
                </button>
            </div>

        </div>
    );
};

export default InterpretationVersions;



