import { Chat } from './Chat';
import { Title } from './Title';

export class Diagnostic extends Chat {
	private titleChat!: Title;

	constructor(
		question: string,
		created: number,
		model: string,
		promptTokens: number,
		completionTokens: number,
		totalTokens: number,
		answer: string
	) {
		// Call the constructor of the base class (Chat)
		super(
			question,
			created,
			model,
			promptTokens,
			completionTokens,
			totalTokens,
			answer
		);
	}

	// Add methods specific to Diagnostic class

	public setTitleChat(titleChat: Title): void {
		this.titleChat = titleChat;
	}

	public getTitleChat(): Title {
		return this.titleChat;
	}

	public toJson(): string {
		const baseJson = super.toJson();
		const thisJson = JSON.stringify({
			titleChat: this.titleChat?.toJson(),
		});

		return (
			baseJson.substring(0, baseJson.length - 1) +
			(baseJson.length > 2 ? ',' : '') +
			thisJson.substring(1)
		);
	}
	public static fromChatGPTJson(json: any, question: string): Diagnostic {
		return new Diagnostic(
			question,
			json['created'],
			json['model'],
			json['usage']['prompt_tokens'],
			json['usage']['completion_tokens'],
			json['usage']['total_tokens'],
			json['choices'][0]['message']['content']
		);
	}

	public static fromJson(json: any): Diagnostic {
		const jsonObject = JSON.parse(json);
		const convertedChat = new Diagnostic(
			jsonObject['question'],
			jsonObject['created'],
			jsonObject['model'],
			jsonObject['promptTokens'],
			jsonObject['completionTokens'],
			jsonObject['totalTokens'],
			jsonObject['answer']
		);

		if (jsonObject['titleChat']) {
			convertedChat.setTitleChat(Title.fromJson(jsonObject['titleChat']));
		}
		return convertedChat;
	}
}

export default Diagnostic;
