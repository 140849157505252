import React, { useState } from 'react';
import { Gender, IPetAndConsultationFormData } from '../../../../models';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { AiOutlineArrowRight } from 'react-icons/ai';
import {
  PiCatBold,
  PiGenderFemaleBold,
  PiGenderMaleBold,
} from 'react-icons/pi';
import { TbDog } from 'react-icons/tb';
import { LuBird } from 'react-icons/lu';

interface StepOneFormProps {
  formData: IPetAndConsultationFormData;
  setFormData: React.Dispatch<React.SetStateAction<IPetAndConsultationFormData>>;
  handleChange: any;
  handleNext: any;
}


export const StepOneForm: React.FC<StepOneFormProps> = ({
  formData,
  setFormData,
  handleChange,
  handleNext,
}
) => {
  const [lastSelectedAnimal, setLastSelectedAnimal] = useState('');
  const [lastSelectedGender, setLastSelectedGender] = useState(Gender.M);
  const [isOtherSelected, setIsOtherSelected] = useState(false); // Track if "Other" is selected

  const handlePetGender = (gender: Gender) => {
    setLastSelectedGender(gender);
    formData.gender = gender
  };

  const handleAnimalSelect = (animalType: string) => {
    setLastSelectedAnimal(animalType);

    if (animalType === '') {
      setIsOtherSelected(true); // Show the text field for "Other"
    } else {
      setIsOtherSelected(false); // Hide the text field for "Other"
    }

    setFormData((prevData) => ({
      ...prevData,
      animal: animalType,
    }));
  };

  return (
    <div>
      <Form.Group controlId="petType">
        <Form.Label>
          <b>Animal</b> <span className="text-red h5">*</span>
        </Form.Label>
        <div className="py-2">
          <Row>
            <Col className="d-flex justify-content-center">
              <Button
                className={`selection ${lastSelectedAnimal === 'Dog' ? 'selected' : ''
                  }`}
                onClick={() => handleAnimalSelect('Dog')}
              >
                <TbDog />
                Dog
              </Button>
            </Col>
            <Col className="d-flex justify-content-center">
              <Button
                className={`selection ${lastSelectedAnimal === 'Cat' ? 'selected' : ''
                  }`}
                onClick={() => handleAnimalSelect('Cat')}
              >
                <PiCatBold />
                Cat
              </Button>
            </Col>
            <Col className="d-flex justify-content-center">
              <Button
                className={`selection ${lastSelectedAnimal === 'Bird' ? 'selected' : ''
                  }`}
                onClick={() => handleAnimalSelect('Bird')}
              >
                <LuBird />
                Bird
              </Button>
            </Col>
            <Col className="d-flex justify-content-center">
              <Button
                className={`selection ${isOtherSelected ? 'selected' : ''
                  }`}
                onClick={() => handleAnimalSelect('')}
              >
                Other
              </Button>
            </Col>
          </Row>
        </div>
        {isOtherSelected && (
          <div className="animal-textfield py-2">
            <Form.Control
              className={`textFieldForm ${formData.animal === '' ? 'pulsateBtn' : ''
                }`}
              type="text"
              name="animal"
              placeholder="Enter animal type"
              value={formData.animal}
              onChange={handleChange}
            />
          </div>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="petBreed">
        <Form.Label>
          <b>Breed</b>
        </Form.Label>
        <Form.Control
          name="breed"
          className="textFieldForm"
          value={formData.breed}
          onChange={handleChange}
          placeholder={`Enter the breed of the ${formData.animal === '' ? 'pet' : formData.animal
            }`}
        />
      </Form.Group>
      <Form.Group controlId="petGender">
        <Form.Label>
          <b>Gender</b>
        </Form.Label>
        <div className="py-2">
          <Row>
            <Col className="d-flex justify-content-center">
              <Button
                className={`selection male ${lastSelectedGender === Gender.M
                  ? 'selectedMale'
                  : ''
                  }`}
                onClick={() => handlePetGender(Gender.M)}
              >
                <PiGenderMaleBold />
                Male
              </Button>
            </Col>
            <Col className="d-flex justify-content-center">
              <Button
                className={`selection female ${lastSelectedGender === Gender.F
                  ? 'selectedFemale'
                  : ''
                  }`}
                onClick={() => handlePetGender(Gender.F)}
              >
                <PiGenderFemaleBold />
                Female
              </Button>
            </Col>
          </Row>
        </div>
      </Form.Group>
      <hr className="hrForm" />
      <div className="d-flex justify-content-end p-0">
        <Button
          className="next_btn"
          onClick={handleNext}
          disabled={formData.animal === ''}
        >
          Next &nbsp;&nbsp;
          <AiOutlineArrowRight className="make-diagnostic-icon" />
        </Button>
      </div>
    </div>
  );
};

export default StepOneForm;



