import React, { useState, useEffect } from 'react';
import { Consultation } from '../../../../models/Consultation';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { truncate, toInitialCase } from '../../../../utils/utils';
import { formatDistanceToNow } from 'date-fns';
import { IoIosArrowForward } from 'react-icons/io';
import './ConsultationCard.scss';

interface ConsultationCardProps {
  consultation?: Consultation;
}
export const ConsultationCard: React.FC<ConsultationCardProps> = ({
  consultation,
}) => {
  const navigate = useNavigate();
  const pet = consultation?.getPet()!;
  const latestDiagnostic = consultation?.getMostRecentDiagnostic()!;

  return (
    <div className="consultationCard m-2">
      <header className="d-flex align-items-center justify-content-center text-center">
        <h2 className="py-2 px-4 mb-0">
          {truncate(latestDiagnostic.getTitleChat().getAnswer())}
        </h2>
      </header>
      <hr className="pt-0 mt-0" />

      <Row className="px-3">
        <Col>
          <div>
            <h3>Animal</h3>
            <p>{toInitialCase(pet.getAnimalType)}</p>
          </div>
        </Col>
        <Col>
          <div>
            <h3>Breed</h3>
            <p>
              {pet.getBreed.trim() !== '' ? (
                toInitialCase(pet.getBreed)
              ) : (
                <span>Was not provided</span>
              )}
            </p>
          </div>
        </Col>
      </Row>

      <Row className="px-3">
        <Col>
          <div>
            <h3>Symptoms</h3>
            <div>
              <ul className="resultUl ml-4">
                {consultation?.getSymptoms().map((item, index) => (
                  <li className="">{toInitialCase(item.trim())}</li>
                ))}
              </ul>
            </div>
          </div>
        </Col>
        <Col>
          <div>
            <h3>Diagnostics</h3>
            <p>
              {consultation?.getDiagnostics().length === 1 ? (
                <span>There is only 1 diagnostic version</span>
              ) : (
                <span>
                  {consultation?.getDiagnostics().length} diagnostic versions
                </span>
              )}
            </p>
          </div>
        </Col>
      </Row>

      <div className="lowerZone">
        <div className="d-flex justify-content-center">
          <button
            className="fw-550 p-2 mt-3 mb-2"
            onClick={() => {
              navigate(`/${consultation?.getId()}/result`);
            }}
          >
            Details <IoIosArrowForward></IoIosArrowForward>
          </button>
        </div>
        <hr className="pb-0 mb-0" />
        <footer className="text-center p-2 fw-600">
          {consultation &&
            formatDistanceToNow(
              new Date(
                consultation.getLatestDiagnosticCreatedTimeStamp() * 1000
              ),
              { addSuffix: true }
            )}
        </footer>
      </div>
    </div>
  );
};

export default ConsultationCard;
