import React, { ReactNode } from "react";
import { Stepper, Step } from 'react-form-stepper';

interface ProgressBProps {
    activeStepValue:number;
    setActiveStepValue:React.Dispatch<React.SetStateAction<number>>;
}

export const ProgressB: React.FC<ProgressBProps> = ({activeStepValue,setActiveStepValue}) => {

  return (
    <Stepper activeStep={activeStepValue} className="progressContainer">
        <Step onClick={() => {setActiveStepValue(1)}} label="Animal Info" />
        <Step onClick={() => {setActiveStepValue(2)}}  label="Additional Info" />
        <Step onClick={() => {setActiveStepValue(3)}}  label="Symptoms and Analyses" />
    </Stepper>
  );
};

export default ProgressB;
