import React, { useState, useEffect } from 'react';
import { HeaderNav, Loader, GoBackButton } from '../../common';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { AddUserModal } from './addUserModal/AddUserModal';
import { ToastContainer, toast } from 'react-toastify';
import { useData } from '../../../context/DataContext'; // Import your DataContext
import { MdKeyboardArrowDown } from 'react-icons/md';
import {
  addNewUser,
  getUsersOfOrganization,
  getListOfUsagesPerMonthOfOrganization,
  getUsageMonthOfOrganization,
} from '../../../services/API/AdminService';
import { getOrganizationName } from '../../../services/API/UserService';
import debounce from 'lodash/debounce';
import firebase from 'firebase/compat/app';
import GraphUsageForMonth from './graphs/GraphUsageForMonth';
import GraphUsageForToday from './graphs/GraphUsageForToday';
import GraphUsersStatusPie from './graphs/GraphUsersStatusPie';
import InformationCard from './graphs/InformationCard';
import { User } from '../../../models';
import { UsersTable } from './usersTable/UsersTable';
import 'react-toastify/dist/ReactToastify.css';
import './AdminPage.scss';
import { DayUsagesMap } from '../../../models';
import { getDaysInMonthInArray } from '../../../services/businessLogic/AdminService';

interface NewUser {
  email: string;
  role: string;
}

interface UsageField {
  costInUSD: number;
  averageCost: number;
  countDiagnostics: number;
}

export const AdminPage = () => {
  const user = firebase.auth().currentUser;
  const navigate = useNavigate();
  const { customClaims } = useData();
  const [showAddUserPopup, setShowAddUserPopup] = useState(false);
  const [listOfUsagePerMonth, setListOfUsagePerMonth] = useState<string[]>([]);
  const [organizationName, setOrganizationName] = useState<string>('');
  const [users, setUsers] = useState<User[]>([]);
  const [daysUsage, setDaysUsage] = useState<DayUsagesMap>({});
  const [isMonthDropdownOpen, setMonthDropdownOpen] = useState(false);
  const [isDayDropdownOpen, setDayDropdownOpen] = useState(false);
  const [usageFields, setUsageFields] = useState<UsageField>({
    costInUSD: 0,
    averageCost: 0,
    countDiagnostics: 0,
  });
  const [selectedMonthYear, setSelectedMonthYear] = useState(
    getCurrentMonthYear()
  );

  const [selectedCurrentDay, setSelectedCurrentDay] = useState(
    getBrusselsCurrentDay()
  );
  function getBrusselsCurrentDay() {
    return new Date(
      new Date().toLocaleDateString('en-US', { timeZone: 'Europe/Brussels' })
    ).getDate();
  }

  function getCurrentMonthYear(): string {
    const brusselsDate = new Date(
      new Date().toLocaleString('en-US', { timeZone: 'Europe/Brussels' })
    );
    return `${brusselsDate.getMonth() + 1}-${brusselsDate.getFullYear()}`;
  }

  const debouncedFetchUsers = debounce(async () => {
    try {
      const response = await getUsersOfOrganization(await user!.getIdToken());
      if (!response.ok) {
        toast.error(await response.text(), {
          autoClose: 10000,
          draggable: true,
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (response.ok) {
        setUsers(await response.json());
      }
    } catch (error) {
      console.error(
        `Error with fetching all the users from organization: ${error}`
      );
    } finally {
    }
  }, 100);

  const debouncedFetchUsagesPerMonth = debounce(async () => {
    try {
      const response = await getListOfUsagesPerMonthOfOrganization(
        await user!.getIdToken()
      );
      if (!response.ok) {
        toast.error(await response.text(), {
          autoClose: 10000,
          draggable: true,
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (response.ok) {
        const data = await response.json();
        setListOfUsagePerMonth(data);
      }
    } catch (error) {
      console.error(
        `Error with fetching all the usages per month from organization: ${error}`
      );
    } finally {
    }
  }, 100);

  const debouncedFetchOrganizationName = debounce(async () => {
    try {
      const response = await getOrganizationName(await user!.getIdToken());
      if (!response.ok) {
        toast.error(await response.text(), {
          autoClose: 10000,
          draggable: true,
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (response.ok) {
        const data = await response.json();
        setOrganizationName(data);
      }
    } catch (error) {
      console.error(`Error with fetching the organization name: ${error}`);
    } finally {
    }
  }, 100);

  const debouncedFetchUsageSpecificMonth = debounce(async () => {
    try {
      const response = await getUsageMonthOfOrganization(
        await user!.getIdToken(),
        selectedMonthYear
      );
      if (!response.ok) {
        toast.error(await response.text(), {
          autoClose: 10000,
          draggable: true,
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (response.ok) {
        const data = await response.json();
        setDaysUsage(data.days);
        setUsageFields(data.fields);
      }
    } catch (error) {
      console.error(`Error with fetching the organization name: ${error}`);
    }
  }, 100);

  useEffect(() => {
    debouncedFetchUsers();
    debouncedFetchUsagesPerMonth();
    debouncedFetchOrganizationName();
    debouncedFetchUsageSpecificMonth();
    // Cleanup function
    return () => {
      // Cancel any pending debounced calls during cleanup
      debouncedFetchUsers.cancel();
      debouncedFetchUsagesPerMonth.cancel();
      debouncedFetchOrganizationName.cancel();
      debouncedFetchUsageSpecificMonth.cancel();
    };
  }, [selectedMonthYear]);

  const handleAddUser = () => {
    setShowAddUserPopup(true);
  };

  const handlePopupCancel = () => {
    setShowAddUserPopup(false);
  };

  const handleInviteUser = async (receivedUser: NewUser) => {
    toast.info(
      `Starting the invitation process to our platfrom for ${receivedUser.email}`,
      {
        autoClose: 10000,
        draggable: true,
        position: toast.POSITION.TOP_RIGHT,
      }
    );

    const response = await addNewUser(
      await user!.getIdToken(),
      receivedUser.role,
      receivedUser.email
    );
    if (response.ok) {
      const successMessage = await response.text();
      toast.success(successMessage, {
        autoClose: 10000,
        draggable: true,
        position: toast.POSITION.TOP_RIGHT,
      });
      debouncedFetchUsers();
    } else {
      const errorMessage = await response.text();
      toast.error(errorMessage, {
        autoClose: 10000,
        draggable: true,
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  if (customClaims && customClaims.role !== 'admin') {
    navigate('/');
  }

  return (
    <div className="">
      <HeaderNav />
      <div className="m-3 d-flex justify-content-center">
        <Row className="px-3 organizationManageContainer">
          <Col className="p-0 m-0" md={12}>
            <div className="mt-3 ml-4">
              <GoBackButton navigateTo="/"></GoBackButton>
            </div>
          </Col>
          <Col className="p-0 m-0 mt-2" md={12}>
            <h1 className="mt-3 ml-4">
              Manage Organization{' '}
              {organizationName ? <span>- {organizationName}</span> : ''}
            </h1>
            <Row id="dashboard">
              <Col md={12}>
                <h2 className="mt-3 ml-4">
                  Dashboard for the usages of {selectedMonthYear}
                </h2>
              </Col>
              <Col className="ml-4" md={12}>
                <Row className="p-0 m-0">
                  <div
                    className="mt-3 mr-4 py-1 text-center monthYearSelector"
                    onClick={() => setMonthDropdownOpen(!isMonthDropdownOpen)}
                  >
                    Selected Month: <b>{selectedMonthYear}</b>
                    <MdKeyboardArrowDown></MdKeyboardArrowDown>
                  </div>
                  {isMonthDropdownOpen && (
                    <div className={`dropdownContent `}>
                      {listOfUsagePerMonth
                        .sort()
                        .reverse()
                        .map((month, index) => (
                          <div
                            key={index}
                            className="dropdownItem  text-center"
                            onClick={() => setSelectedMonthYear(month)}
                          >
                            <b>
                              {month == selectedMonthYear ? (
                                <span>(Selected) {month}</span>
                              ) : (
                                <span>{month}</span>
                              )}
                            </b>
                          </div>
                        ))}
                    </div>
                  )}

                  <div
                    className="mt-3 mr-4 py-1 text-center daySelector"
                    onClick={() => setDayDropdownOpen(!isDayDropdownOpen)}
                  >
                    Selected Day: <b>{selectedCurrentDay}</b>
                    <MdKeyboardArrowDown></MdKeyboardArrowDown>
                  </div>
                  {isDayDropdownOpen && (
                    <div className={`dropdownContent dayContent`}>
                      {getDaysInMonthInArray(selectedMonthYear).map(
                        (day, index) => (
                          <div
                            key={index}
                            className="dropdownItem text-center dayItem"
                            onClick={() => setSelectedCurrentDay(day)}
                          >
                            <b>
                              {day == selectedCurrentDay ? (
                                <span>(Selected) {day}</span>
                              ) : (
                                <span>{day}</span>
                              )}
                            </b>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </Row>
              </Col>
            </Row>
            <Row className="m-0 p-0">
              <Col md={7} className="m-0 p-0 my-3">
                <GraphUsageForToday
                  daysUsage={daysUsage}
                  selectedDay={selectedCurrentDay}
                ></GraphUsageForToday>
              </Col>
              <Col md={5} className="m-0 p-0 my-3">
                <GraphUsersStatusPie users={users}></GraphUsersStatusPie>
              </Col>
              <Col md={12} className="m-0 p-0 my-3">
                <GraphUsageForMonth
                  monthYear={selectedMonthYear}
                  daysUsage={daysUsage}
                  setCurrentDay={setSelectedCurrentDay}
                ></GraphUsageForMonth>
              </Col>
            </Row>

            <Row className="m-0 r-0">
              <Col className="m-0 r-0 d-flex justify-content-center">
                <InformationCard
                  informationValue={usageFields?.costInUSD}
                  informationText={`Cost so far for ${selectedMonthYear}`}
                  money={true}
                  fixedNumbersForValue={2}
                ></InformationCard>
              </Col>

              <Col className="m-0 r-0 d-flex justify-content-center">
                <InformationCard
                  informationValue={usageFields?.averageCost}
                  informationText={`Average cost per diagnostic for ${selectedMonthYear}`}
                  money={true}
                  fixedNumbersForValue={3}
                ></InformationCard>
              </Col>
              <Col className="m-0 r-0 d-flex justify-content-center">
                <InformationCard
                  informationValue={usageFields?.countDiagnostics}
                  informationText={`Diagnostics for ${selectedMonthYear}`}
                  money={false}
                  fixedNumbersForValue={0}
                ></InformationCard>
              </Col>
            </Row>

            <Row className="m-0 r-0">
              <Col id="userTableContainer" md={12} className="m-0 r-0">
                <UsersTable
                  daysUsage={daysUsage}
                  selectedMonthYear={selectedMonthYear}
                  users={users}
                  handleAddUser={handleAddUser}
                ></UsersTable>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <AddUserModal
        show={showAddUserPopup}
        onCancel={handlePopupCancel}
        onInviteUser={handleInviteUser}
      />
      <ToastContainer />
    </div>
  );
};

export default AdminPage;
