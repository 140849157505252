import React, { useState } from 'react';
import './OrganizationLinkingPage.scss';
import Loader from '../../common/loader/Loader';
import firebase from 'firebase/compat/app';
import email_example from '../../../assets/imgs/organizationLinking/invitation_email_structure.png';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../../services/API/AuthService';
import { BsPatchCheck } from 'react-icons/bs';
import { linkUserWithOrganizationRequest } from '../../../services/API/AuthService';

export const OrganizationLinkingPage = () => {
  const user = firebase.auth().currentUser;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let timer: NodeJS.Timeout | null = null; // Initialize a timer variable
  const [showMoreWhere, setShowMoreWhere] = useState(false);
  const [showMoreWhat, setShowMoreWhat] = useState(false);
  const [invitationCode, setinvitationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, isDisabled] = useState(true);

  const linkUserWithOrganization = () => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(async () => {
      if (user) {
        try {
          setIsLoading(true);
          const response = await linkUserWithOrganizationRequest(
            await user.getIdToken(),
            invitationCode
          );

          if (response.ok) navigate('/');
          if (!response.ok) {
            console.error(
              'Error linking user with organization: ' + response.statusText
            );
            const json = await response.json();
            setErrorMessage(json.error);
          }
        } catch (error) {
          console.error('Error linking user with organization:', error);
        } finally {
          setIsLoading(false);
        }
      }
    }, 500); // Adjust the delay as needed
  };

  const handleCodeValidation = (code: string) => {
    // Remove leading and trailing spaces from the input
    isDisabled(true);

    // Check if the input field is blank (only spaces)
    if (code === '') {
      setErrorMessage('Code cannot be blank.');
      return;
    }

    // Check if the input code contains a '/' character
    if (!code.includes('/')) {
      setErrorMessage('Code must contain a "/".');
      return;
    }

    // Check if the input code contains a '/' character
    if (!(code.length === 41)) {
      setErrorMessage(
        `The code that you provided doesn't looks good to us, it looks a little too ${
          code.length < 41 ? 'short' : 'long'
        } to us. You need to ${code.length < 41 ? 'add' : 'remove'} ${Math.abs(
          code.length - 41
        )} character(s).`
      );
      return;
    }

    const splittedCode = code.split('/');
    const splittedCodeLeft = splittedCode[0];

    if (splittedCodeLeft.length < 20) {
      setErrorMessage(
        `The code that you provided doesn't looks good to us, there should be ${Math.abs(
          splittedCodeLeft.length - 20
        )} more characters before the '/' and ${Math.abs(
          splittedCodeLeft.length - 20
        )} less after it.`
      );
      return;
    }
    if (splittedCodeLeft.length > 20) {
      setErrorMessage(
        `The code that you provided doesn't looks good to us, there should be ${Math.abs(
          splittedCodeLeft.length - 20
        )} less characters before the '/' and ${Math.abs(
          splittedCodeLeft.length - 20
        )} more after it.`
      );
      return;
    }
    isDisabled(false);
    setErrorMessage('');
  };

  return (
    <div className="divOrgLinkingPage">
      <div className="d-flex justify-content-center">
        <div className="welcomeContainer">
          <h2 className="text-center fw-400">
            <i>Hey there! it looks like you are new here?</i>
          </h2>
          <h1 className="text-center">
            Welcome{' '}
            <span className="text-accentDarker">
              {user && user.displayName?.split(' ')[0]}
            </span>
          </h1>
          <hr />
          {isLoading ? (
            <div style={{ maxHeight: '40vh', marginTop: '10rem' }}>
              <Loader
                text={`We're on it ${
                  user?.displayName?.split(' ')[0]
                }! Connecting your account and the organization...`}
              />
            </div>
          ) : (
            <div>
              <p className="px-1">
                Before you can explore our amazing website, ensure your profile
                is linked to an organization.
              </p>
              <p className="px-1">
                To complete the linking process between your profile and the
                organization, please copy and paste the invitation code from
                your invitation email.
              </p>

              <div className="d-flex justify-content-center pt-4 pb-1">
                <span className="text-center fw-700">
                  Your personal invitation code:
                </span>
              </div>

              <div className="d-flex justify-content-center">
                <input
                  className="text-center"
                  type="text"
                  placeholder="xxxxxxxxxxxxxxxxxx/xxxxxxxxxxxxxxxxxx"
                  value={invitationCode}
                  onChange={(e) => {
                    setinvitationCode(e.target.value.trim());
                    handleCodeValidation(e.target.value.trim());
                  }}
                />
                <br />
                <br />
              </div>

              <p
                id="errorMessageLinking"
                className="text-center text-danger fw-600"
              >
                {errorMessage}
              </p>

              <div className="d-flex justify-content-center">
                <button
                  disabled={disabled}
                  onClick={() => linkUserWithOrganization()}
                >
                  Validate code &nbsp;&nbsp;&nbsp;
                  <BsPatchCheck />
                </button>
              </div>
            </div>
          )}

          <div className={`p-1 pt-5`}>
            <h4 className="" onClick={() => setShowMoreWhere(!showMoreWhere)}>
              Where can I find the invitation code?{' '}
              <span className="show-more-link">
                {showMoreWhere ? 'Show Less' : 'Show More'}
              </span>
            </h4>
            <div className={`show-more-content ${showMoreWhere ? 'show' : ''}`}>
              {/* Content to show/hide */}
              <div className="d-flex justify-content-center">
                <img
                  className="pb-2"
                  src={email_example}
                  alt="Example of the structure of the invitation email"
                />
              </div>
              <p className="">
                As evident in the image, you'll spot the invitation code neatly
                nestled within the center of the email, encased in a discreet
                black box.
              </p>
            </div>
          </div>

          <div className={`p-1`}>
            <h4 className="" onClick={() => setShowMoreWhat(!showMoreWhat)}>
              What if I don't have any invitation email?{' '}
              <span className="show-more-link">
                {showMoreWhat ? 'Show Less' : 'Show More'}
              </span>
            </h4>
            <div className={`show-more-content ${showMoreWhat ? 'show' : ''}`}>
              {/* Content to show/hide */}

              <p className="">
                The invitation email is very important as it serves as proof of
                your invitation by one of your organization's administrators or
                a personal invitation from us. It ensures that our platform is
                accessed by dedicated professionals in the field of pet care.
              </p>
            </div>
          </div>
          <hr />
          <p className="px-1">
            If you think that something might be wrong, don't hesitate to{' '}
            <a className="text-accent" href="mailto:vassist-supp@outlook.com">
              reach out to us via email.
            </a>
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <p style={{ maxWidth: '50rem' }} className="px-5 text-center">
          If you find yourself doubting your account choice, remember that you
          can log out at any time to try out other accounts or email addresses.
        </p>
      </div>
      <div className="d-flex justify-content-center pb-5">
        <span onClick={() => logOut()} className="signOutLinking text-center">
          Sign out&nbsp;&nbsp;&nbsp;
          <FiLogOut />
        </span>
      </div>
    </div>
  );
};

export default OrganizationLinkingPage;
