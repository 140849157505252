import React, { useState, useEffect } from 'react';
import { Consultation } from '../../../models/Consultation';
import { HeaderNav, Loader } from '../../common';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Carousel } from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import { GoBackButton } from '../../common/buttons/goBackButton/GoBackButton';
import { getLatestConsultations } from '../../../services/API/UserService';
import { ConsultationCard } from './consultationCard/ConsultationCard';
import './HistoryPage.scss';

export const HistoryPage = () => {
  const user = firebase.auth().currentUser;
  const [isLoading, setIsLoading] = useState(true);
  const [recentConsultations, setRecentConsultations] = useState<
    Consultation[]
  >([]);
  let debounceTimeout: NodeJS.Timeout;

  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const response = await getLatestConsultations(
          await user!.getIdToken(),
          null
        );
        if (response.ok) {
          const consultationsJSON = await response.json();

          const convertedConsultations = consultationsJSON.map(
            (consultationJSON: string) =>
              Consultation.fromJson(JSON.parse(consultationJSON))
          );

          setRecentConsultations(convertedConsultations);
        }
      } catch (error) {
        console.error('Error fetching diagnosis history:', error);
      } finally {
        setIsLoading(false);
      }
    };

    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      fetchConsultations();
    }, 100);
  }, []);

  return (
    <div>
      {isLoading ? '' : <HeaderNav />}
      <div className="d-flex justify-content-center mx-3">
        {!isLoading && recentConsultations ? (
          <Row className="py-1 px-2 m-0 historyContainer ">
            <Col md={12}>
              <div className="mt-3 ml-3">
                <GoBackButton navigateTo="/"></GoBackButton>
              </div>
            </Col>
            <Col className="p-0 m-0 interpretationsTextArea" lg={12}>
              <h1>
                History of consultations of{' '}
                {user?.displayName ? (
                  <span>{user?.displayName.split(' ')[0]}</span>
                ) : (
                  <span>user</span>
                )}
              </h1>
              <h2 className="pl-4 ml-2 text-secondary">
                The 5 most recent consultations
              </h2>
              {recentConsultations.length !== 0 ? (
                <div className="cardContainer my-3 mx-5">
                  {recentConsultations?.map((consultation, index) => {
                    return (
                      <ConsultationCard
                        consultation={consultation}
                        key={index}
                      ></ConsultationCard>
                    );
                  })}
                </div>
              ) : (
                <div className="d-flex justify-content-center text-center">
                  <h3>No consultations yet!</h3>
                </div>
              )}
            </Col>
          </Row>
        ) : (
          <div style={{ height: '40rem' }}>
            <Loader text="Retrieving the most recent consultations..." />
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryPage;
