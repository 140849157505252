import React from 'react'; // Import the React module
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FiLogOut } from 'react-icons/fi';
import { VscAccount } from 'react-icons/vsc';

interface UserProfilePopupButtonProps {
  onClickEventPopUp: any;
  onClickEventSignOut: any;
  userClaims: any;
  minimized: boolean;
}

export const UserProfilePopupButton: React.FC<UserProfilePopupButtonProps> = ({
  onClickEventPopUp,
  onClickEventSignOut,
  userClaims,
  minimized,
}) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="account-wrapper">
        <div className="p-2 text-decoration-none account">
          {!minimized && (
            <div>
              <Row className="m-0 p-0">
                <Col
                  onClick={onClickEventPopUp}
                  className="m-0 p-0 px-1 py-2 accountInfo"
                >
                  <div className="d-flex justify-content-center">
                    <div className="">
                      <b>{userClaims?.name}</b> <br />
                      <b>{userClaims?.email}</b>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={2}
                  className="m-0 p-0 mt-1 p-2 logOutBtn text-center"
                  onClick={onClickEventSignOut}
                >
                  <span>
                    <FiLogOut className=""></FiLogOut>
                  </span>
                </Col>
              </Row>
            </div>
          )}

          {minimized && (
            <div>
              <div
                className="logOutBtn  d-flex justify-content-center"
                onClick={onClickEventSignOut}
              >
                <span>
                  <FiLogOut className=""></FiLogOut>
                </span>
              </div>
              <div
                className="accountInfo d-flex justify-content-center"
                onClick={onClickEventPopUp}
              >
                <span>
                  <VscAccount></VscAccount>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfilePopupButton;
