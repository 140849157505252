import React, { useEffect, useState } from 'react';
import { VscAccount } from 'react-icons/vsc';
import { IoCloseOutline } from 'react-icons/io5';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useData } from '../../../../context/DataContext';
import { capitalizeFirstWord } from '../../../../utils/utils';
import firebase from 'firebase/compat/app';
import './UserProfilePopup.scss';

interface UserProfilePopupProps {
  show: boolean;
  onClose: () => void;
  setSidebarMinimized: any;
}

export const UserProfilePopup: React.FC<UserProfilePopupProps> = ({
  show,
  onClose,
  setSidebarMinimized,
}) => {
  const [user, setUser] = useState<firebase.User | null>(null); // State to store the user data
  const [isEditing, setIsEditing] = useState(false);
  const [editedEmail, setEditedEmail] = useState('');
  const [editedDisplayName, setEditedDisplayName] = useState('');
  const { customClaims } = useData();

  // Load the user data when the component mounts
  React.useEffect(() => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      setUser(currentUser);
      setEditedEmail(currentUser.email || '');
      setEditedDisplayName(currentUser.displayName || '');
    }
  }, []);

  // Function to handle user attribute updates
  const handleUpdateProfile = async () => {
    try {
      await user?.updateProfile({
        displayName: editedDisplayName,
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  // Add an event listener to update the state when the window is resized
  useEffect(() => {
    if (show) {
      setSidebarMinimized(true);
    }else{
      setSidebarMinimized(false);
    }
  }, [show]);



  return (
    <Modal show={show} onHide={onClose} id="accountPopup">
      <Modal.Header>
        <Row className="w-100 m-auto">
          <Col md={12} className="d-flex justify-content-between">
            <VscAccount className="text-accent h1"></VscAccount>
            <button onClick={onClose} className="text-secondary ">
              <IoCloseOutline className="text-secondary"></IoCloseOutline>
            </button>
          </Col>

          <Col md={12} className=" pt-3">
            <h2 className="fw-600">User profile</h2>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className="mx-4">
        {user && (
          <div>
            <p>
              <strong>Email:</strong>
            </p>
            <p>{editedEmail}</p>
            <p>
              <strong>Display Name:</strong>
            </p>
            {isEditing ? (
              <input
                type="text"
                className="userProfileTextFieldForm"
                value={editedDisplayName}
                onChange={(e) => setEditedDisplayName(e.target.value)}
              />
            ) : (
              <p>{editedDisplayName}</p>
            )}
            <p>
              <strong>Role:</strong>
            </p>
            <p>{capitalizeFirstWord(customClaims?.role)}</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isEditing ? (
          <Button
            variant="primary"
            className="saveChanges_btn"
            onClick={handleUpdateProfile}
          >
            Save Changes
          </Button>
        ) : (
          <Button
            variant="primary"
            className="editProfile_btn"
            onClick={() => setIsEditing(true)}
          >
            Edit Profile
          </Button>
        )}
        <Button variant="secondary" className="Close_btn" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserProfilePopup;
