import React, { useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { BsTrash3 } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import { MdOutlineAutoGraph } from 'react-icons/md';
import { Gender, IPetAndConsultationFormData } from '../../../../models';

interface StepThreeFormProps {
  formData: IPetAndConsultationFormData;
  setFormData: React.Dispatch<React.SetStateAction<IPetAndConsultationFormData>>;
  handleChange: any;
  handlePrev: any;
  requestError: any;
  handleFormSubmit: any;
}


export const StepThreeForm: React.FC<StepThreeFormProps> = ({
  formData,
  setFormData,
  handleChange,
  handlePrev,
  requestError,
  handleFormSubmit,
}) => {
  const [showTips, setShowTips] = useState(false);
  const toggleTips = () => {
    setShowTips(!showTips);
  };


  const handleAddAnalysisField = () => {
    setFormData((prevData) => ({
      ...prevData,
      analyses: [...prevData.analyses, ''], // Add an empty analyses field
    }));
  };
  const handleRemoveAnalysisField = (index: number) => {
    setFormData((prevData) => {
      const updatedAnalysis = [...prevData.analyses];
      updatedAnalysis.splice(index, 1); // Remove the analysis field at the specified index
      return {
        ...prevData,
        analyses: updatedAnalysis,
      };
    });
  };


  return (
    <div>
      <Form.Group className="mb-3" controlId="symptoms">
        <Form.Label>
          <b>Symptoms</b> (seperate with a ',' if there are more
          than one)
        </Form.Label>
        <Form.Control
          required
          className="textFieldForm"
          name="symptoms"
          type="textarea"
          value={formData.symptoms}
          onChange={handleChange}
          placeholder={`The symptoms of the ${formData.animal}`}
        />
        {formData.symptoms.split(',').length >= 1 &&
          formData.symptoms.split(',')[0] !== '' && (
            <div>
              <br />
              <p>Interpreted symptoms for the {formData.animal}</p>
              <ul>
                {formData.symptoms
                  .trim()
                  .split(',')
                  .map((item, index) => (
                    <li>{item}</li>
                  ))}
              </ul>
            </div>
          )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="bloodResult">
        <Form.Label>
          <b>Analyses</b>
        </Form.Label>{' '}
        <div
          className="text-secondary  bg-accentSoft py-3 px-5"
          id="guidelines"
        >
          <span className="fw-700 pt-2 d-flex justify-content-center">
            Here are some guidelines that can be used to guide our
            AI in the right way!
          </span>
          <hr />
          <div
            className={`tips-content ${showTips ? 'show' : 'hide'}`}
          >
            <ul className="small">
              {' '}
              <li>
                Try to specify what type of analysis is provided.
                This will give our AI more context and will require
                less thinking! <br />
                For example:
                <ul>
                  <li>
                    "This is the blood analysis from 1 month ago,
                    and these are the results: ...",
                  </li>
                  <li>
                    "This is the urinalysis of the {formData.animal}
                  </li>
                </ul>
              </li>
              <li>
                Use a consistent separator for multiple values, such
                as commas (,) or semicolons (;), and avoid mistaking
                the separator as a delimiter for decimal numbers.
              </li>
              <li>
                Ensure the AI will recognize the units of
                measurement.
              </li>
              <li>
                Try to use proper medical terminology and precision.
              </li>
            </ul>
          </div>

          <button
            onClick={toggleTips}
            className={`toggle-button ${showTips ? 'show' : 'hide'
              }`}
          >
            {showTips ? 'Hide Tips' : 'Show Tips'}
          </button>
        </div>
        {formData.analyses.map((item, index) => (
          <div key={index} className="mt-2">
            <p className="fw-500 ">
              Analysis {index + 1}
              {formData.analyses.length > 1 && (
                <button
                  type="button"
                  className="removeAnalysis"
                  onClick={() => handleRemoveAnalysisField(index)}
                >
                  <BsTrash3 />
                </button>
              )}
            </p>
            <Form.Control
              as="textarea"
              rows={7}
              className="analysisField"
              value={item}
              onChange={(e) => {
                const updatedAnalysis = [...formData.analyses];
                updatedAnalysis[index] = e.target.value;
                setFormData((prevData) => ({
                  ...prevData,
                  analyses: updatedAnalysis,
                }));
              }}
              placeholder="Paste or type in here your analysis ..."
            />
          </div>
        ))}
        <div className="d-flex justify-content-center">
          <button
            className="addAnalysis"
            type="button"
            onClick={handleAddAnalysisField}
          >
            <FiPlus />
            &nbsp;&nbsp;Analysis
          </button>
        </div>
      </Form.Group>
      <hr className="hrForm" />
      <Row>
        {requestError && (
          <Col lg={12} className="d-flex justify-content-center">
            <p className="p-3 mb-2 bg-danger text-white rounded">
              {requestError}
            </p>
          </Col>
        )}
        <Col className="d-flex justify-content-start p-0 mt-1">
          <button className="back_btn" onClick={handlePrev}>
            <AiOutlineArrowLeft /> &nbsp;&nbsp; Back
          </button>
        </Col>
        <Col className="d-flex justify-content-end p-0 mt-1">
          <Button
            className="make-diagnostic-btn"
            type="submit"
            onClick={handleFormSubmit}
          >
            Generate Interpretation &nbsp;&nbsp;
            <MdOutlineAutoGraph className="make-diagnostic-icon" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default StepThreeForm;



