import 'firebase/compat/auth';
import { baseUrlDev, baseUrlProd } from './Shared';

export async function addNewUser(idToken: string, role: string, email: string) {
  return await fetch(`${baseUrlProd}/addNewUser`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({ role: role, email: email }),
  });
}
export async function getUsersOfOrganization(idToken: string) {
  return await fetch(`${baseUrlProd}/getUsersOfOrganization`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
}

export async function getListOfUsagesPerMonthOfOrganization(idToken: string) {
  return await fetch(`${baseUrlProd}/getListOfUsagesPerMonthOfOrganization`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
}
export async function getUsageMonthOfOrganization(
  idToken: string,
  monthYear: string
) {
  return await fetch(`${baseUrlProd}/getUsageMonthOfOrganization`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({ monthYear: monthYear }),
  });
}
