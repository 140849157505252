import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { CustomTooltipToday } from './CustomTooltipToday';
import firebase from 'firebase/compat/app';
import { DayUsagesMap } from '../../../../models';
import './Graph.scss';

interface GraphUsageForTodayProps {
  daysUsage: DayUsagesMap;
  selectedDay:number;
}

export const GraphUsageForToday: React.FC<GraphUsageForTodayProps> = ({
  daysUsage,selectedDay
}) => {



  let chartData: any = [];
  let totalCosts: any = [];
  if (daysUsage && daysUsage[selectedDay]) {
    const dayUsage = daysUsage[selectedDay];

    for (let i = 0; i <= 23; i++) {
      const hourUsage = dayUsage.usageMapPerHour[i];

      if (hourUsage === undefined) {
        chartData.push({
          x: i,
          y: 0,
        });
        totalCosts.push({
          x: i,
          y: 0,
        });
      } else {
        chartData.push({
          x: i,
          y: dayUsage.usageMapPerHour[i].length,
        });
        const totalCostForHour = dayUsage.usageMapPerHour[i]
          ? dayUsage.usageMapPerHour[i].reduce(
              (acc: number, usage: any) => acc + usage.totalCostUSDDiagnostic,
              0
            )
          : 0;
        totalCosts.push({
          x: i,
          y: totalCostForHour,
        });
      }
    }
  } else {
    for (let i = 0; i <= 23; i++) {
      chartData.push({
        x: i,
        y: 0,
      });
    }
  }

  return (
    <div className="d-flex justify-content-center">
      {daysUsage && (
        <div
          className="usagesGraph"
          id="usagesPerHourForToday"
          style={{ height: '18rem' }}
        >
          <h3 className="text-secondary text-center">
            Total generated diagnostics for <b>today</b> - divided per hour
          </h3>
          <ResponsiveLine
            data={[
              { id: 'diagnostics', data: chartData },
              { id: 'totalCost', data: totalCosts },
            ]}
            margin={{ top: 10, right: 60, bottom: 75, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: false,
              reverse: false,
            }}
            enableSlices="x"
            yFormat=" >-.1d"
            axisTop={null}
            axisRight={{
              legend: 'Total Cost',
              legendOffset: 50, // Adjust the offset as needed
              legendPosition: 'middle',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisBottom={{
              legend: 'Hour of generation',
              legendOffset: 40, // Adjust the offset as needed
              legendPosition: 'middle',
              tickSize: 5, // Adjust the size of the ticks
              tickPadding: 5, // Adjust the padding of the ticks
              tickRotation: 0,
            }}
            curve="monotoneX"
            useMesh={true}
            enableArea={true}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: `Generated diagnostics`,
              legendOffset: -50,
              legendPosition: 'middle',
            }}
            pointSize={6}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            enablePoints={true}
            enableGridX={false}
            enableGridY
            sliceTooltip={(props) => <CustomTooltipToday {...props} />}
            colors={{ scheme: 'set3' }}
          />
        </div>
      )}
    </div>
  );
};

export default GraphUsageForToday;
