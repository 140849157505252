export function toInitialCase(str: string): string {
  if (str.length === 0 || str == undefined) {
    return str;
  }

  const words = str.toLowerCase().split(' ');

  for (let i = 0; i < words.length; i++) {
    console.log(words[i])
    if(words[i] && words[i].trim() !== "")
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }

  return words.join(' ');
}

export function capitalizeFirstWord(str: string): string {
  if (str.length === 0 || str === undefined) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function calculateAge(dateOfBirth: string): number {
  const birthDate = new Date(
    dateOfBirth.replace(
      /CEST|UTC|GMT|PST|EST|CST|MST|PDT|EDT|CDT|MDT|ACST|ACDT|ACWST|ADT|AEDT|AEST|AFT|AKDT|AKST|ALMT|AMST|AMT|ANAT|AQTT|ART|AST|AWST|AZOST|AZOT|AZT|BNT|BIOT|BIT|BOT|BRST|BRT|BST|BTT|CAT|CCT|CDT|CEST|CET|CHADT|CHAST|CHOT|CHOST|CHST|CHUT|CIST|CKT|CLST|CLT|COST|COT|CST|CT|CVT|CWST|CXT|DAVT|DDUT|DFT|EASST|EAST|EAT|ECT|EDT|EEST|EET|EGST|EGT|EST|ET|FET|FJT|FKST|FKT|FNT|GALT|GAMT|GET|GFT|GILT|GIT|GMT|GST|GYT|HDT|HAEC|HST|HKT|HMT|HOVST|HOVT|ICT|IDLW|IDT|IOT|IRDT|IRKT|IRST|IST|JST|KALT|KGT|KOST|KRAT|KST|LHST|LINT|MAGT|MART|MAWT|MDT|MET|MEST|MHT|MIST|MIT|MMT|MSK|MST|MUT|MVT|MYT|NCT|NDT|NFT|NOVT|NPT|NST|NT|NUT|NZDT|NZST|OMST|ORAT|PDT|PET|PETT|PGT|PHOT|PHT|PHST|PKT|PMDT|PMST|PONT|PST|PWT|PYST|PYT|RET|ROTT|SAKT|SAMT|SAST|SBT|SCT|SDT|SGT|SLST|SRET|SRT|SST|SYOT|TAHT|THA|TFT|TJT|TKT|TLT|TMT|TRT|TOT|TVT|ULAST|ULAT|UTC|UYST|UYT|UZT|VET|VLAT|VOLT|VOST|VUT|WAKT|WAST|WAT|WEST|WET|WIB|WIT|WITA|WGST|WGT|WST|YAKT|YEKT/gi,
      ''
    )
  );

  const currentDate = new Date();
  const age = currentDate.getFullYear() - birthDate.getFullYear();

  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())
  ) {
    return age - 1;
  }

  return age;
}

export function truncate(str: string) {
  return str.length > 75 ? str.substring(0, 75) + '...' : str;
}
