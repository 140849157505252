import React from 'react'; // Import the React module
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

interface NavItemProps {
  to: string;
  textValue: string;
  iconValue: any;
  minimized: boolean;
}

export const NavItem: React.FC<NavItemProps> = ({
  to,
  textValue,
  iconValue,
  minimized,
}) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="nav-link-wrapper">
        <Link to={to} className="nav-link p-2 text-decoration-none">
          <Row className="m-0 p-0">
            <Col
              xs={minimized ? 12 : 2}
              sm={minimized ? 12 : 2}
              md={minimized ? 12 : 2}
              className={`m-0 p-0 ${
                minimized ? 'd-flex justify-content-center' : ''
              }`}
            >
              <span>{iconValue}</span>
            </Col>
            <Col className="m-0 p-0 textPart">{textValue}</Col>
          </Row>
        </Link>
      </div>
    </div>
  );
};

export default NavItem;
