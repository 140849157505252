import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { SiMinutemailer } from 'react-icons/si';
import { MdOutlineContactSupport } from 'react-icons/md';
import { HeaderNav, Loader, GoBackButton } from '../../common';
import { sendEmail } from '../../../services/API/UserService';
import firebase from 'firebase/compat/app';
import './ContactPage.scss';

export const ContactPage: React.FC = () => {
  const user = firebase.auth().currentUser;

  const [name, setName] = useState(user?.displayName ? user.displayName : '');
  const [email, setEmail] = useState(user?.email ? user.email : '');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [nameValid, setNameValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [subjectValid, setSubjectValid] = useState(false);
  const [messageValid, setMessageValid] = useState(false);

  const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)

  const [formValid, setFormValid] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [touchedFields, setTouchedFields] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });
  const [timeoutIds, setTimeoutIds] = useState<{
    [key: string]: NodeJS.Timeout | null;
  }>({
    name: null,
    email: null,
    subject: null,
    message: null,
  });

  const handleTouched = (name: string) => {
    if (timeoutIds[name]) {
      clearTimeout(timeoutIds[name] as NodeJS.Timeout);
    }

    const newTimeoutId = setTimeout(() => {
      setTouchedFields((prevTouchedFields) => ({
        ...prevTouchedFields,
        [name]: true,
      }));
    }, 500); // Adjust the delay time as needed (in milliseconds)

    setTimeoutIds((prevTimeoutIds) => ({
      ...prevTimeoutIds,
      [name]: newTimeoutId,
    }));
  };

  useEffect(() => {
    validateForm();
  }, [
    name,
    email,
    subject,
    message,
    nameValid,
    emailValid,
    subjectValid,
    messageValid,
  ]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    handleTouched(name); // Call the handleTouched function when the user starts typing
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'subject':
        setSubject(value);
        break;
      case 'message':
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    const newErrors = { name: '', email: '', subject: '', message: '' };
    setNameValid(true);
    setEmailValid(true);
    setSubjectValid(true);
    setMessageValid(true);

    if (!name) {
      newErrors.name = 'Name is required';
      setNameValid(false);
    }

    if (!email || !isValidEmail(email)) {
      newErrors.email =
        'Enter a valid email address as the following structure: "john.doe@example.com"';
      setEmailValid(false);
    }

    if (!subject) {
      newErrors.subject = 'Subject is required';
      setSubjectValid(false);
    }

    if (!message) {
      newErrors.message = 'Message is required';
      setMessageValid(false);
    }
    const isValid = nameValid && emailValid && subjectValid && messageValid;
    setFormValid(isValid);
    setErrors(newErrors);
  };

  type SubmitFunction = (name: string, email: string, subject: string, message: string) => Promise<void>;

  const debounce = (func: SubmitFunction, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;

    return async (...args: Parameters<SubmitFunction>) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        try {
          await func(...args);
        } catch (error) {
          console.error("Something went wrong: ", error);
        } finally {
          setIsLoading(false);
        }
      }, delay);
    };
  };

  const debouncedSubmit = debounce(async (name, email, subject, message) => {
    setIsLoading(true);
    const response = await sendEmail(name, email, subject, message);
    if (response.ok) {
      setIsDone(true);
    } else {
      throw new Error(response.statusText);
    }
  }, 500);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (formValid) {
      debouncedSubmit(name, email, subject, message);
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  return (
    <div>
      <HeaderNav />
      {isDone ? (
        <div className='d-flex justify-content-center'>
          <div className='py-1 px-2 mx-5 resultBoxSupportPage'>
            <div className="mt-3 ml-3">
              <GoBackButton></GoBackButton>
            </div>
            <div className='p-3 ml-3'>
              <h1>Message delivered successfully to our team!</h1>
              <p className='text-secondary py-2'>This is what we got:</p>
              <div className='bg-accentSofter p-2' style={{ borderRadius: "1rem" }}>
                <p className='text-secondary py-2'>Full Name</p>
                <b>{name}</b>
                <p className='text-secondary py-2'>Email</p>
                <b>{email}</b>
                <p className='text-secondary py-2'>Subject</p>
                <b>{subject}</b>
                <p className='text-secondary py-2'>Your Message</p>
                <b>{message}</b>
              </div>

            </div>
          </div>
        </div>
      ) : (
        <div>
          {isLoading ? (
            <div style={{ height: '45rem' }}>
              <Loader text='Connecting you with our team'></Loader>
            </div>
          ) :
            (
              <div className="d-flex justify-content-center mt-5 mx-5">
                <Form
                  onSubmit={handleSubmit}
                  className="resultBoxSupportPage"
                >
                  <div className="my-3 ml-3">
                    <GoBackButton></GoBackButton>
                  </div>
                  <div className='py-5 text-center'>
                    <h1>What's on your mind? <MdOutlineContactSupport className='text-accent'></MdOutlineContactSupport></h1>
                    <p className='text-secondary'>Your questions and special requests are always welcome.</p>
                    <p>Full Name</p>
                    <p>{ }</p>
                  </div>

                  <Row className='m-0 p-0 '>
                    <Col lassName='m-0 p-0'>
                      <p className="h5">
                        Full Name
                      </p>
                      <input
                        className='inputSupportPage'
                        required
                        type="text"
                        id="name"
                        name="name" // Add name attribute
                        value={name}
                        onChange={handleChange} // Use the handleChange function
                        placeholder="John Doe"
                      />
                      {errors.name !== ''
                        ? touchedFields.name && (
                          <p className="error-message">{errors.name}</p>
                        )
                        : ''}
                    </Col>
                    <Col lassName='m-0 p-0'>
                      <p className="h5">
                        Your email
                      </p>
                      <input
                        required
                        type="email"
                        id="email"
                        name="email" // Add name attribute
                        value={email}
                        onChange={handleChange} // Use the handleChange function
                        placeholder="John.Smith@hotmail.com"
                        className='inputSupportPage'
                      />
                      {errors.email !== ''
                        ? touchedFields.email && (
                          <p className="error-message">{errors.email}</p>
                        )
                        : ''}
                    </Col>
                  </Row>
                  <br />

                  <Col lassName='m-0 p-0 '>
                    <p className="h5">
                      Subject
                    </p>
                    <input
                      required
                      type="text"
                      id="subject"
                      name="subject" // Add name attribute
                      value={subject}
                      onChange={handleChange} // Use the handleChange function
                      placeholder="The button is not working on the homepage..."
                      className='inputSupportPage'
                    />
                    {errors.subject !== ''
                      ? touchedFields.subject && (
                        <p className="error-message">{errors.subject}</p>
                      )
                      : ''}
                  </Col>
                  <br />
                  <Col lassName='m-0 p-0 '>
                    <p className="h5">
                      Message
                    </p>
                    <textarea
                      required
                      id="message"
                      rows={4}
                      name="message"
                      value={message}
                      onChange={handleChange}
                      className='inputSupportPage'
                    />


                    {errors.message !== ''
                      ? touchedFields.message && (
                        <p className="error-message">{errors.message}</p>
                      )
                      : ''}
                  </Col>
                  <div id="diagnostic" className="d-flex justify-content-center mb-5">
                    <Button
                      className="send-email-btn"
                      type="submit"
                      disabled={!formValid}
                    >
                      Send Email &nbsp;&nbsp;
                      <SiMinutemailer className="send-email-icon" />
                    </Button>
                  </div>
                </Form>
              </div>

            )
          }
        </div>


      )}



    </div>
  );
};

export default ContactPage;
