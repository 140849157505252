import React from 'react';
import { SliceTooltipProps } from '@nivo/line';

interface CustomTooltipProps extends SliceTooltipProps {}

export const CustomTooltipToday: React.FC<CustomTooltipProps> = ({
  axis,
  slice,
}) => {
  function handleYAxis(serieId: any, point: any): React.ReactNode {

    if (serieId === 'totalCost') {
      return (
        <div>
          (x-axis) <b>Generated at: </b>
          {point.data.x}h
          <hr />
          (y-axis) <b>Total cost for this hour:</b> $
          {parseFloat(point.data.y).toFixed(3)}
        </div>
      );
    }

    if (serieId === 'diagnostics') {
      return (
        <div>
          (y-axis) <b>Diagnostics:</b> {point.data.y}
        </div>
      );
    }
  }

  return (
    <div className="customToolTip">
      <div>
        {slice.points.map((point) => (
          <div key={point.id}>{handleYAxis(point.serieId, point)}</div>
        ))}
      </div>
    </div>
  );
};

export default CustomTooltipToday;

