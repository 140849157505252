import React from 'react'; // Import the React module
import { useNavigate } from 'react-router-dom';
import { IoChevronBackOutline } from 'react-icons/io5';
import Button from 'react-bootstrap/Button';
import './GoBackButton.scss';

interface GoBackButtonProps {
  onClick?: () => void;
  btnText?: string;
  navigateTo?: string;
}

export const GoBackButton: React.FC<GoBackButtonProps> = ({
  onClick,
  btnText,
  navigateTo,
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (onClick) {
      onClick(); // Call the custom onClick function if provided
    } else {
      if (navigateTo) {
        navigate(navigateTo); // Navigate back to the previous page by default
      } else {
        navigate(-1); // Navigate back to the previous page by default
      }
    }
  };

  return (
    <Button className="back-btn" onClick={handleGoBack}>
      <IoChevronBackOutline className="back-btn-icon" />
      &nbsp;&nbsp;{btnText ? btnText : 'Back'}
    </Button>
  );
};
