import { Diagnostic } from './Diagnostic';
import { Pet } from './Pet';

export class Consultation {
  private id: string;
  private symptoms: string[];
  private analyses: string[];
  private diagnostics: Diagnostic[];
  private pet: Pet;
  private latestDiagnosticCreatedTimeStamp: number;

  constructor(
    id: string | null,
    symptoms: string[],
    analyses: string[],
    diagnostics: Diagnostic[],
    pet: Pet
  ) {
    this.id = id || '';
    this.symptoms = symptoms;
    this.analyses = analyses;
    this.diagnostics = diagnostics;
    this.pet = pet;
    this.latestDiagnosticCreatedTimeStamp =
      this.calculateLatestDiagnosticCreatedTimeStamp(diagnostics);
  }

  public getId(): string {
    return this.id;
  }

  public getPet(): Pet {
    return this.pet;
  }

  private calculateLatestDiagnosticCreatedTimeStamp(
    diagnostics: Diagnostic[]
  ): number {
    const latestDiagnostic = diagnostics.reduce((latest, diagnostic) => {
      return diagnostic.getCreated() > latest
        ? diagnostic.getCreated()
        : latest;
    }, 0);

    return latestDiagnostic;
  }

  public getLatestDiagnosticCreatedTimeStamp(): number {
    return this.latestDiagnosticCreatedTimeStamp;
  }

  public getSymptoms(): string[] {
    return this.symptoms;
  }

  public getAnalyses(): string[] {
    return this.analyses;
  }

  public getDiagnostics(): Diagnostic[] {
    return this.diagnostics;
  }

  public getSortedDiagnostics(): Diagnostic[] {
    const sortedDiagnostics = Array.from(this.diagnostics.values()).sort(
      (a, b) => b.getCreated() - a.getCreated()
    );
    return sortedDiagnostics;
  }

  public getMostRecentDiagnostic(): Diagnostic {
    return this.getSortedDiagnostics()[0];
  }

  public getSymptomsToString(): string {
    return this.getSymptoms().join(', ');
  }

  public getAnalysesToString(): string {
    return this.getAnalyses()
      .map(
        (item, index) =>
          `\n\n\n\n This is the ${index + 1} Analysis: \n\n  ${item}`
      )
      .join(', ');
  }


  public setId(id: string): void {
    this.id = id;
  }

  public setSymptoms(symptoms: string[]): void {
    this.symptoms = symptoms;
  }

  public setAnalyses(analyses: string[]): void {
    this.analyses = analyses;
  }

  public setDiagnostics(diagnostics: Diagnostic[]): void {
    this.diagnostics = diagnostics;
    this.latestDiagnosticCreatedTimeStamp = this.calculateLatestDiagnosticCreatedTimeStamp(diagnostics);
  }

  public setPet(pet: Pet): void {
    this.pet = pet;
  }


  public static fromJson(json: any): Consultation {
    const pet = Pet.fromJson(json.pet);

    const diagnosticsArray: Diagnostic[] = [];
    if (json.diagnostics) {
      Object.values(json.diagnostics).forEach((diagnosticJson: any) => {
        const diagnostic = Diagnostic.fromJson(diagnosticJson);
        diagnosticsArray.push(diagnostic);
      });
    }

    const consultation = new Consultation(
      json.id,
      json.symptoms,
      json.analyses,
      diagnosticsArray,
      pet
    );

    return consultation;
  }

  public toJSON(): string {
    const diagnosticsArray: any[] = [];
    this.diagnostics.forEach((diagnostic) => {
      diagnosticsArray.push(diagnostic.toJson());
    });

    const consultationObject = {
      id: this.id,
      symptoms: this.symptoms,
      analyses: this.analyses,
      diagnostics: diagnosticsArray,
      pet: this.pet.toCreatePetJSON(),
    };

    return JSON.stringify(consultationObject);
  }
}
