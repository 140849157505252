import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import './Graph.scss';

interface GraphUsageForTodayProps {
  users: User[];
}

interface User {
  id: string;
  role: string;
  email: string;
  status: string;
}
interface StatusCounts {
  [status: string]: number;
}

const getStatusColor = (status: string): string => {
  const colors = {
    active: 'rgb(10, 194, 99)',
    invited: 'rgb(255, 193, 7)',
    disabled: 'rgb(181, 27, 9)',
    // Add more statuses and their colors if needed
  };

  return (colors as { [key: string]: string })[status] || 'rgb(255, 127, 80)'; // Fallback color
};

export const GraphUsersStatusPie: React.FC<GraphUsageForTodayProps> = ({
  users
}) => {
  // Count the occurrences of each status
  const statusCounts: StatusCounts = users.reduce((counts, user) => {
    counts[user.status] = (counts[user.status] || 0) + 1;
    return counts;
  }, {} as StatusCounts);

  const pieChartData = Object.entries(statusCounts).map(([status, count]) => ({
    color: getStatusColor(status),
    id: status,
    label: status,
    value: count,
  }));

  return (
    <div className="d-flex justify-content-center  mx-2">
      <div className="usagesGraph" id="userStatus" style={{ height: '18rem' }}>
        <h3 className="text-secondary text-center">
          Status Distribution Among Users
        </h3>
        <ResponsivePie
          data={pieChartData}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          borderWidth={1}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsThickness={2}
          arcLabelsSkipAngle={10}
          borderColor={'#F9FAFB'}
          animate
          activeOuterRadiusOffset={8}
          arcLabelsTextColor="#ffffff"
          colors={{
            datum: 'data.color',
          }}
          arcLinkLabelsTextColor={{
            from: 'color',
          }}
        />
      </div>
    </div>
  );
};

export default GraphUsersStatusPie;
