import React from 'react';
import { Consultation, IPetAndConsultationFormData } from '../../../../models';
import { capitalizeFirstWord } from '../../../../utils/utils';
import { CiEdit } from 'react-icons/ci';
import { Row, Col, Button } from 'react-bootstrap';

interface ConsultationDetailsProps {
    isEditMode: boolean;
    consultationInFormData: IPetAndConsultationFormData;
    setConsultationInFormData: React.Dispatch<React.SetStateAction<IPetAndConsultationFormData>>;
}

export const ConsultationDetails: React.FC<ConsultationDetailsProps> = ({
    isEditMode, consultationInFormData, setConsultationInFormData
}
) => {

    const renderAnalysisFields = () => {
        return consultationInFormData.analyses.map((analysis, index) => (
            <div key={index} className="mt-2">
                <p className="fw-500 ">
                    Analysis {index + 1}
                    {consultationInFormData.analyses.length >= 1 && (
                        <button
                            type="button"
                            className="removeAnalysis p-2"
                            onClick={() => handleRemoveAnalysisField(index)}
                        >
                            Remove
                        </button>
                    )}
                </p>
                <textarea
                    rows={3}
                    className="analysisField w-100 p-2"
                    value={analysis}
                    onChange={(e) => handleAnalysisFieldChange(index, e.target.value)}
                    placeholder="Paste or type in here your analysis ..."
                />
            </div>
        ));
    };

    const handleAddAnalysisField = () => {
        setConsultationInFormData((prevData) => ({
            ...prevData,
            analyses: [...prevData.analyses, ''], // Add an empty analysis field
        }));
    };

    const handleRemoveAnalysisField = (index: number) => {
        setConsultationInFormData((prevData) => ({
            ...prevData,
            analyses: prevData.analyses.filter((_, i) => i !== index),
        }));
    };

    const handleAnalysisFieldChange = (index: number, value: string) => {
        setConsultationInFormData((prevData) => {
            const updatedAnalyses = [...prevData.analyses];
            updatedAnalyses[index] = value;
            return {
                ...prevData,
                analyses: updatedAnalyses,
            };
        });
    };




    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setConsultationInFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div>
            <h3 className=" p-3 ml-3">Consultation</h3>
            <p className="p-0 m-0 mx-4  p-3  tips-content text-secondary bg-accentSoft small">
                Providing comprehensive information about symptoms and
                analyses is crucial. The more data we have, the better
                equipped the AI is to identify potential issues.
                Please include all relevant details to improve the quality of the analysis.
            </p>
            <hr className="mx-3" />

            <Row className="p-0 m-0">
                <Col className="p-0 m-0 mx-4  p-3">
                    <h4>Symptoms </h4>
                    <p className='text-secondary'>{isEditMode ? "Seperate symptoms with a comma and a space ', '" : ""}</p>
                    <div>
                        <ul className="resultUl ml-4">
                            {consultationInFormData.symptoms && consultationInFormData.symptoms.split(", ").length > 0
                                ? consultationInFormData.symptoms.split(", ").map((item, index) => (
                                    <li key={index} className="">
                                        {capitalizeFirstWord(item.trim())}
                                    </li>
                                ))
                                : <li className="">Not provided</li>}
                        </ul>
                    </div>
                    {isEditMode ? (
                        <div>
                            <br />
                            <textarea
                                className='inputFieldTextAreaEditConsultation'
                                rows={4}
                                name="symptoms"
                                value={consultationInFormData.symptoms ? consultationInFormData.symptoms : ''}
                                onChange={handleChange}
                            />
                        </div>
                    ) : ""}
                </Col>
            </Row>
            <Row className="p-0 m-0">
                <Col className="p-0 m-0 mx-4  p-3">
                    <h4>Analyses</h4>
                    {isEditMode ? (
                        <>
                            {renderAnalysisFields()}
                            <div className="d-flex justify-content-center">
                                <button
                                    className="addAnalysis"
                                    type="button"
                                    onClick={handleAddAnalysisField}
                                >
                                    Add Analysis
                                </button>
                            </div>
                        </>
                    ) : (
                        <div>
                            <ul className="resultUl ml-4">
                                {consultationInFormData.analyses && consultationInFormData.analyses.length > 0
                                    ? consultationInFormData.analyses.map((item, index) => (
                                        <li key={index} className="">
                                            {capitalizeFirstWord(item.trim())}
                                        </li>
                                    ))
                                    : <li className="">Not provided</li>}
                            </ul>
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default ConsultationDetails;



