import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useData } from '../../../context/DataContext';
import { HeaderNav, Loader, GoBackButton } from '../../common';
import { Consultation, Diagnostic, IPetAndConsultationFormData, initializePetAndConsultationFormData, initializePetAndConsultationFormDataFillWithConsultation } from '../../../models'; // Import the Consultation model
import { Row, Col } from 'react-bootstrap';
import { generateDiagnostic, patchConsultation } from '../../../services/API/UserService';
import { getConsultation } from '../../../services/API/UserService';
import { PetDetails, ConsultationDetails, InterpretationVersions } from "./components"
import { CiEdit } from 'react-icons/ci';
import { MdOutlineCancel } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import firebase from 'firebase/compat/app';
import './ResultPage.scss';

export const ResultPage: React.FC = () => {
  const user = firebase.auth().currentUser;
  const navigate = useNavigate();
  const { consultationId } = useParams<{ consultationId: string }>();
  const { setReturnedConsultation } = useData();
  const { returnedConsultation } = useData() as { returnedConsultation: Consultation | null; };
  const [currentDiagnose, setCurrentDiagnose] = useState<Diagnostic | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [consultationInFormData, setConsultationInFormData] = useState(initializePetAndConsultationFormData());

  if (consultationId?.trim() === '') {
    navigate('/');
  }

  let debounceTimer: ReturnType<typeof setTimeout>;

  const fetchConsultation = async () => {
    try {
      const response = await getConsultation(
        await user!.getIdToken(),
        consultationId!
      );
      if (response.ok) {
        const consultationJson = await response.json();
        const responededConsultation = Consultation.fromJson(consultationJson);
        console.log(responededConsultation);
        setReturnedConsultation(responededConsultation);
        setConsultationInFormData(initializePetAndConsultationFormDataFillWithConsultation(responededConsultation))
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error fetching consultation:', error);
    }
  };

  useEffect(() => {
    setCurrentDiagnose(
      returnedConsultation?.getDiagnostics()[
      returnedConsultation?.getDiagnostics().length - 1
      ]
    );
    if (returnedConsultation == null || returnedConsultation.getId() !== consultationId) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(fetchConsultation, 100);
    } else {
      setIsLoading(false);
      setConsultationInFormData(initializePetAndConsultationFormDataFillWithConsultation(returnedConsultation!))
    }

  }, [consultationId, returnedConsultation]);

  const handleRetryInterpretationSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (returnedConsultation) {
      try {
        setIsLoading(true);
        const response = await generateDiagnostic(
          await user!.getIdToken(),
          returnedConsultation
        );
        if (response.ok) {
          const consultationJson = await response.json();
          const responededConsultation =
            Consultation.fromJson(consultationJson);
          console.log(responededConsultation);
          setReturnedConsultation(responededConsultation);
        } else {
          console.error('Error:', response.status);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };


  const handleSaveEdit = async (consultationFormData: IPetAndConsultationFormData) => {
    try {
      setIsLoading(true);
      const response = await patchConsultation(await user!.getIdToken(), returnedConsultation!.getId(), consultationFormData);
      if (response.ok) {
        const consultationJson = await response.json();
        const responededConsultation = Consultation.fromJson(consultationJson);
        console.log(responededConsultation);
        setReturnedConsultation(responededConsultation);
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    setIsEditMode(true)
    setConsultationInFormData(initializePetAndConsultationFormDataFillWithConsultation(returnedConsultation!))
  }

  const handleCancel = () => {
    setIsEditMode(false)
    setConsultationInFormData(initializePetAndConsultationFormDataFillWithConsultation(returnedConsultation!))
  }
  const handleSave = () => {
    handleSaveEdit(consultationInFormData)
    setIsEditMode(false);
  }

  return (
    <div>
      {isLoading ? '' : <HeaderNav />}
      <div className="d-flex justify-content-center">
        {!isLoading && returnedConsultation ? (
          <Row className="py-1 px-2 mx-5 resultBox">
            <Col md={12}>
              <div className="mt-3 ml-3">
                <GoBackButton></GoBackButton>
              </div>
            </Col>
            <Col className="p-0 m-0 interpretationsTextArea" lg={8}>
              <h1>Interpretations</h1>
              {currentDiagnose ? (
                <div className="diagnostic-prompt mx-4 p-3">
                  <h2>{currentDiagnose?.getTitleChat()?.getAnswer()}</h2>
                  <hr />
                  {currentDiagnose
                    .getAnswer()
                    .split('\n')
                    .map((line, index) => (
                      <React.Fragment key={index}>
                        {line.replace(/\\n/g, '')}
                        <br />
                      </React.Fragment>
                    ))}
                </div>
              ) : (
                ''
              )}
            </Col>

            <Col className="p-0 m-0 interpretationsVersions" lg={4}>
              <InterpretationVersions
                returnedConsultation={returnedConsultation}
                currentDiagnose={currentDiagnose}
                setCurrentDiagnose={setCurrentDiagnose}
                handleRetryInterpretationSubmit={handleRetryInterpretationSubmit}
              ></InterpretationVersions>
            </Col>

            <Col className="p-0 m-0 dataInterpretationBox">
              <h2>Source Data for Interpretation</h2>
              {isEditMode ? (
                <div className='d-flex'>
                  <button onClick={handleCancel} className="ml-4 cancelResultPageBtn">Cancel&nbsp; <MdOutlineCancel></MdOutlineCancel></button>
                  <button onClick={handleSave} className="ml-4 editResultPageBtn">Save&nbsp; <AiOutlineCheckCircle></AiOutlineCheckCircle></button>
                </div>
              ) : (
                <button onClick={handleEdit} className="ml-4 editResultPageBtn">Edit&nbsp; <CiEdit></CiEdit></button>
              )}
              <Row className="p-0 m-0">
                <Col lg={6} className="p-0 m-0 petDetailsContainer">
                  <PetDetails
                    consultationInFormData={consultationInFormData}
                    setConsultationInFormData={setConsultationInFormData}
                    isEditMode={isEditMode}></PetDetails>
                </Col>
                <Col lg={6} className="p-0 m-0 consultationDetailsContainer">
                  <ConsultationDetails
                    consultationInFormData={consultationInFormData}
                    setConsultationInFormData={setConsultationInFormData}
                    isEditMode={isEditMode}></ConsultationDetails>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <div style={{ height: '40rem' }}>
            <Loader text="Loading interpretation..." />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultPage;
