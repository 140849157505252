import { format } from 'date-fns';


export abstract class Chat {
  private question: string;
  private created: number;
  private model: string;
  private answer: string;
  private promptTokens: number;
  private completionTokens: number;
  private totalTokens: number;
  private date: Date;

  constructor(
    question: string,
    created: number,
    model: string,
    promptTokens: number,
    completionTokens: number,
    totalTokens: number,
    answer: string
  ) {
    this.question = question;
    this.answer = answer;
    this.created = created;
    this.model = model;
    this.promptTokens = promptTokens;
    this.completionTokens = completionTokens;
    this.totalTokens = totalTokens;
    this.date = new Date(this.created * 1000);
  }

  public getQuestion(): string {
    return this.question;
  }

  public getDate(): string {
    return this.date ? format(this.date, 'dd/MM/yyyy - HH:mm:ss') : '';
  }

  public getCreated(): number {
    return this.created;
  }

  public getModel(): string {
    return this.model;
  }

  public getAnswer(): string {
    return this.answer;
  }

  public toJson(): string {
    return JSON.stringify({
      question: this.question,
      created: this.created,
      model: this.model,
      answer: this.answer,
      promptTokens: this.promptTokens,
      completionTokens: this.completionTokens,
      totalTokens: this.totalTokens,
    });
  }

  // public static fromChatGPTJson(json: any, question: string): Chat {
  // 	return new Chat(
  // 		question,
  // 		json['created'],
  // 		json['model'],
  // 		json['usage']['prompt_tokens'],
  // 		json['usage']['completion_tokens'],
  // 		json['usage']['total_tokens'],
  // 		json['choices'][0]['message']['content']
  // 	);
  // }

  // public static fromJson(json: any): Chat {
  // 	const jsonObject = JSON.parse(json);
  // 	const convertedChat = new Chat(
  // 		jsonObject['question'],
  // 		jsonObject['created'],
  // 		jsonObject['model'],
  // 		jsonObject['promptTokens'],
  // 		jsonObject['completionTokens'],
  // 		jsonObject['totalTokens'],
  // 		jsonObject['answer']
  // 	);
  // 	return convertedChat;
  // }
}

export default Chat;
