import React from 'react';
import { HeaderNav } from '../../common';

import './NotFoundPage.scss';
import { useNavigate } from 'react-router-dom';
import { GoBackButton } from '../../common/buttons/goBackButton/GoBackButton';

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <HeaderNav />
      <div className="d-flex justify-content-center mt-5">
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h1 style={{ fontSize: '3em', marginBottom: '20px' }}>
            404 - Not Found
          </h1>
          <p style={{ fontSize: '1.5em', color: '#777' }}>
            Oops! The page you are looking for might be on another link.
          </p>
          <img
            onClick={() => navigate('/')}
            src="https://media.giphy.com/media/14uQ3cOFteDaU/giphy.gif"
            alt="Mario saying Sorry, but your princess is in another castle"
            style={{
              maxWidth: '100%',
              height: 'auto',
              borderRadius: '2rem',
              cursor: 'pointer',
            }}
          />
          <div className="mt-5">
            <GoBackButton navigateTo="/" btnText="Home" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
