import React, { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { User, DayUsagesMap,InformationRecordsAboutUsers,initializeDictFromUsers } from '../../../../models';
import {
  AiOutlineCheckCircle,
  AiOutlineStop,
  AiOutlineDollar,
  AiOutlineTeam,
  AiOutlineMail,
  AiOutlineInfoCircle,
  AiOutlineTool,
} from 'react-icons/ai';
import { CiMail } from 'react-icons/ci';
import { MdOutlineAutoGraph } from 'react-icons/md';
import { Button, Col, Row, Table } from 'react-bootstrap';
import './UsersTable.scss';
import {
  sortUsers,
  assignTotalCostPerUser,
  sumUpTotalCostCurrentMonthPerUserEmail,
} from '../../../../services/businessLogic/AdminService';

interface UsersTableProps {
  users: User[];
  selectedMonthYear: string;
  daysUsage: DayUsagesMap;
  handleAddUser: any;
}

export const UsersTable: React.FC<UsersTableProps> = ({
  users,
  selectedMonthYear,
  daysUsage,
  handleAddUser,
}) => {
  const [hideDisabledUsers, setHideDisabledUsers] = useState(true);

  if (daysUsage) {
    const totalUsagePerUser: InformationRecordsAboutUsers = initializeDictFromUsers(users);
    sumUpTotalCostCurrentMonthPerUserEmail(
      totalUsagePerUser,
      selectedMonthYear,
      daysUsage
    );
    assignTotalCostPerUser(users, totalUsagePerUser);
    sortUsers(users);
  }

  const getStatusLabel = (
    status: string,
    icon: React.ReactNode,
    colorClass: string,
    label: string
  ) => (
    <div className={`d-flex justify-content-center`}>
      <div
        className={`bg-${colorClass} fw-700 p-1 px-2 text-white statusLabels`}
      >
        {icon}
        &nbsp;&nbsp; {label}
      </div>
    </div>
  );

  const handleStatus = (status: string) => {
    switch (status) {
      case 'active':
        return getStatusLabel(
          status,
          <AiOutlineCheckCircle size={25} />,
          'green',
          'Active'
        );
      case 'invited':
        return getStatusLabel(
          status,
          <CiMail size={25} />,
          'warning',
          'Invited'
        );
      case 'disabled':
        return getStatusLabel(
          status,
          <AiOutlineStop size={25} />,
          'red',
          'Disabled'
        );
      default:
        return null;
    }
  };

  return (
    <div  className="userTableContainer my-5 mx-2">
      <Row>
        <Col className="m-0 p-0" md={6}>
          <h2 className="pt-4 pl-5">List of users</h2>
        </Col>
        <Col className="m-0 p-0" md={6}>
          <p className="pb-2 pt-4 pl-5">
            <input
              type="checkbox"
              checked={hideDisabledUsers}
              onChange={() => setHideDisabledUsers(!hideDisabledUsers)}
            />
            <span> Hide Disabled Users</span>
          </p>
        </Col>
      </Row>
      <Table hover responsive className="m-0 p-0">
        <thead>
          <tr>
            <th>
              <div className="text-center">
                <AiOutlineMail className="text-secondary" size={25} />
                &nbsp;&nbsp;Email
              </div>
            </th>

            <th>
              <div className="text-center" style={{ minWidth: '5rem' }}>
                <AiOutlineTeam className="text-secondary" size={25} />
                &nbsp;&nbsp;Role
              </div>
            </th>
            <th>
              <div className="text-center">
                <MdOutlineAutoGraph className="text-secondary" size={25} />
                &nbsp;&nbsp;Diagnostics
              </div>
            </th>
            <th>
              <div className="text-center">
                <AiOutlineDollar className="text-secondary" size={25} />
                &nbsp;&nbsp;Costs {selectedMonthYear}
              </div>
            </th>
            <th>
              <div className="text-center">
                <AiOutlineInfoCircle className="text-secondary" size={25} />
                &nbsp;&nbsp;Status
              </div>
            </th>
            <th>
              <div className="text-center" style={{ minWidth: '6rem' }}>
                <AiOutlineTool className="text-secondary" size={25} />
                &nbsp;&nbsp;Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) =>
            (hideDisabledUsers && user.status !== 'disabled') ||
            !hideDisabledUsers ? (
              <tr key={user.id}>
                <td className="text-center">{user?.email}</td>
                <td className="text-center">{user?.role}</td>
                <td className="text-center fw-600">
                  {user.totalDiagnosticsCurrentMonthYear !== 0
                    ? user.totalDiagnosticsCurrentMonthYear
                    : '-'}</td>
                <td className="text-center fw-600">
                  {user.totalCostUSDDiagnosticCurrentMonthYear !== 0
                    ? <span>${user.totalCostUSDDiagnosticCurrentMonthYear.toFixed(2)}</span>
                    : '-'}
                </td>
                <td>{handleStatus(user.status)}</td>
                <td className="text-center">
                  {user.status !== 'disabled' && (
                    <Button variant="danger" size="sm">
                      <AiOutlineStop /> Disable
                    </Button>
                  )}
                </td>
              </tr>
            ) : null
          )}
        </tbody>
      </Table>
      <hr className="m-0" />
      <div className="d-flex justify-content-center">
        <button className="addUser my-3" onClick={handleAddUser}>
          <FiPlus /> &nbsp;&nbsp; User
        </button>
      </div>
    </div>
  );
};

export default UsersTable;
