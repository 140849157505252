import { Chat } from "./Chat";

export class Title extends Chat {

  constructor(
    question: string,
    created: number,
    model: string,
    promptTokens: number,
    completionTokens: number,
    totalTokens: number,
    answer: string
  ) {
    // Call the constructor of the base class (Chat)
    super(question, created, model, promptTokens, completionTokens, totalTokens, answer);
  }


  public static fromChatGPTJson(json: any, question: string): Title {
    return new Title(
      question,
      json['created'],
      json['model'],
      json['usage']['prompt_tokens'],
      json['usage']['completion_tokens'],
      json['usage']['total_tokens'],
      json['choices'][0]['message']['content']
    );
  }

  public static fromJson(json: any): Title {
    const jsonObject = JSON.parse(json);
    const convertedChat = new Title(
      jsonObject['question'],
      jsonObject['created'],
      jsonObject['model'],
      jsonObject['promptTokens'],
      jsonObject['completionTokens'],
      jsonObject['totalTokens'],
      jsonObject['answer']
    );
    return convertedChat;
  }
}

export default Title;
