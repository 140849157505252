import { Consultation, IPetAndConsultationFormData } from '../../models';
import { baseUrlDev, baseUrlProd } from './Shared';

export async function generateDiagnostic(
  idToken: string,
  consultation: Consultation
) {
  return await fetch(`${baseUrlProd}/generateDiagnostic`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`, // Include the Firebase Authentication token in the Authorization header
    },
    body: consultation.toJSON(),
  });
}

export async function getConsultation(idToken: string, consultationId: string) {
  return await fetch(`${baseUrlProd}/getConsultation`, { 
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`, // Include the Firebase Authentication token in the Authorization header
    },
    body: JSON.stringify({ consultationId: consultationId }),
  });
}
export async function patchConsultation(idToken: string, consultationId: string, consultationFormData: IPetAndConsultationFormData) {
  return await fetch(`${baseUrlProd}/patchConsultation`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({ 
      consultationId: consultationId, 
      consultationFormData:consultationFormData ,
    }),
  });
}

export async function getLatestConsultations(
  idToken: string,
  lastPreviousDocId: string | null
) {
  return await fetch(`${baseUrlProd}/getLatestConsultations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`, // Include the Firebase Authentication token in the Authorization header
    },
    body: JSON.stringify({ lastPreviousDocId: lastPreviousDocId }),
  });
}

export async function getOrganizationName(idToken: string) {
  return await fetch(`${baseUrlProd}/getOrganizationName`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${idToken}`, // Include the Firebase Authentication token in the Authorization header
    },
  });
}

export async function sendEmail(name: string,email: string,subject: string,message: string) {
  return await fetch(`${baseUrlProd}/sendEmail`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, subject, message }),
    }
  );
}
