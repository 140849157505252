import React from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import { IPetAndConsultationFormData } from '../../../../models';
import { Gender } from '../../../../models'

interface StepTwoFormProps {
  formData: IPetAndConsultationFormData;
  handleChange: any;
  handleNext: any;
  handlePrev: any;
}


export const StepTwoForm: React.FC<StepTwoFormProps> = ({
  formData,
  handleChange,
  handleNext,
  handlePrev
}
) => {

  return (
    <div>
      <Form.Group className="mb-3" controlId="petAge">
        <Form.Label>
          <b>
            How old is the
            <span className="text-accent fw-700">
              {' '}
              {formData.animal}{' '}
            </span>{' '}
          </b>
          (in years)? <span className="text-danger h5">*</span>
        </Form.Label>
        <Form.Control
          required
          name="age"
          className="textFieldForm"
          type="text"
          value={formData.age}
          onChange={handleChange}
          placeholder="Enter the age in years, for example: 7.5 or 0.5 for 6 months"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="petWeight">
        <Form.Label>
          <b>What is the current weight </b>(in kg)?{' '}
          <span className="text-danger h5">*</span>
        </Form.Label>
        <Form.Control
          required
          name="weight"
          className="textFieldForm"
          type="text"
          value={formData.weight}
          onChange={handleChange}
          placeholder={`Enter the weight of the ${formData.animal === '' ? 'pet' : formData.animal
            }`}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="petmedicalHistory">
        <Form.Label>
          <b>Is the <span className='text-accent'>{formData.animal}</span> sterilized?</b>
        </Form.Label>
        <br />
        <select
          className='textFieldForm p-1'
          name="sterilzation"
          value={formData.sterilzation}
          onChange={handleChange}
        >
          <option value={""}>Not sterilized</option>
          {formData.gender == Gender.M ?
            (
              <>
                <option value={"Surgical Castration (Neutering)"}>Surgical Castration (Neutering)</option>
                <option value={"Vasectomy"}>Vasectomy</option>
                <option value={"Chemical Vasectomy (Non-surgical)"}>Chemical Vasectomy (Non-surgical)</option>
              </>
            ) :
            (
              <>
                <option value={"Surgical Spaying"}>Surgical Spaying</option>
                <option value={"Chemical Sterilization"}>Chemical Sterilization</option>
                <option value={"Hysterectomy"}>Hysterectomy</option>
                <option value={"Tubal Ligation"}>Tubal Ligation</option>
                <option value={"Ovariectomy"}>Ovariectomy</option>
              </>
            )}

        </select>

      </Form.Group>

      <Form.Group className="mb-3" controlId="petmedicalHistory">
        <Form.Label>
          <b>Anamnesis of the <span className='text-accent'>{formData.animal}</span></b>
        </Form.Label>
        <textarea
          required
          rows={4}
          name="medicalHistory"
          className="textFieldForm w-100 p-2"
          value={formData.medicalHistory}
          onChange={handleChange}
          placeholder={`The ${formData.animal} has a medical history including allergies, respiratory infections, and digestive issues...`}
        />

      </Form.Group>
      <hr className="hrForm" />
      <Row>
        <Col className="d-flex justify-content-start p-0">
          <button className="back_btn" onClick={handlePrev}>
            <AiOutlineArrowLeft /> &nbsp;&nbsp; Back
          </button>
        </Col>
        <Col className="d-flex justify-content-end p-0">
          <Button
            className="next_btn"
            onClick={handleNext}
            disabled={formData.age === '' || formData.weight === ''}
          >
            Next &nbsp;&nbsp;
            <AiOutlineArrowRight />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default StepTwoForm;



