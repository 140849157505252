import React from 'react';
import './Graph.scss';

interface InformationCardProps {
  informationValue: number;
  informationText: string;
  money: boolean;
  fixedNumbersForValue: number;
}

export const InformationCard: React.FC<InformationCardProps> = ({
  informationValue,
  informationText,
  money,
  fixedNumbersForValue,
}) => {
  return (
    <div className="informationCard text-center">
      <p className="informationValue">
        {money ? (
          <span>
            $
            {informationValue
              ? informationValue.toFixed(fixedNumbersForValue)
              : '0.00'}
          </span>
        ) : (
          <span>{informationValue?informationValue:0}</span>
        )}
      </p>
      <p className="informationText">{informationText}</p>
    </div>
  );
};

export default InformationCard;
