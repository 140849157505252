import React, { useState, useEffect } from 'react'; // Import the React module
import { UserProfilePopup } from './userProfile/UserProfilePopup';
import { logOut } from '../../../services/API/AuthService';
import { useData } from '../../../context/DataContext';
import { BiEnvelope } from 'react-icons/bi';
import { FiHelpCircle } from 'react-icons/fi';
import { TbBrandGoogleAnalytics } from 'react-icons/tb';
import { MdOutlineAutoGraph } from 'react-icons/md';
import { NavItem } from './NavItem';
import { FaHistory } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { UserProfilePopupButton } from './userProfile/UserProfilePopupButton';
import logo from '../../../assets/imgs/logo.png';
import minimizedLogo from '../../../assets/imgs/minimized-logo.png';
import './HeaderNav.scss';
import {
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowLeft,
} from 'react-icons/md';
export function HeaderNav() {
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [sidebarMinimized, setSidebarMinimized] = useState(
    window.innerWidth <= 1787
  );
  const { customClaims } = useData();

  useEffect(() => {
    const handleResize = () => {
      setSidebarMinimized(window.innerWidth <= 1787);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = async () => {
    try {
      logOut();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const toggleSidebar = () => {
    setSidebarMinimized(!sidebarMinimized);
  };

  return (
    <nav className={`left-sidebar ${sidebarMinimized ? 'minimized' : ''}`}>
      <div className="d-flex justify-content-center py-3 otherThanToggleBtn">
        <img
          src={sidebarMinimized ? minimizedLogo : logo}
          alt="logo"
          className="img-fluid text-center"
          style={{ maxWidth: '15rem' }}
        />
      </div>
      <div className="toggle-btn" onClick={toggleSidebar}>
        {sidebarMinimized ? (
          <span>
            <MdKeyboardDoubleArrowRight></MdKeyboardDoubleArrowRight>
          </span>
        ) : (
          <span>
            <MdKeyboardDoubleArrowLeft></MdKeyboardDoubleArrowLeft>
          </span>
        )}
      </div>
      <div className='otherThanToggleBtn'>
        <hr />
        <h2 className="text-secondary">Menu</h2>
        <NavItem
          to="/"
          textValue="New Diagnostic"
          iconValue={<MdOutlineAutoGraph></MdOutlineAutoGraph>}
          minimized={sidebarMinimized}
        ></NavItem>
        <NavItem
          to="/history"
          textValue="History"
          iconValue={<FaHistory></FaHistory>}
          minimized={sidebarMinimized}
        ></NavItem>
        {customClaims?.role === 'admin' && (
          <div>
            <h2 className="text-secondary">Organization</h2>
            <NavItem
              to="/organization#dashboard"
              textValue="Dashboard"
              iconValue={<TbBrandGoogleAnalytics></TbBrandGoogleAnalytics>}
              minimized={sidebarMinimized}
            ></NavItem>
            <NavItem
              to="/organization#userTableContainer"
              textValue="Users"
              iconValue={<FiUsers></FiUsers>}
              minimized={sidebarMinimized}
            ></NavItem>
          </div>
        )}
        <h2 className="text-secondary">General</h2>
        <NavItem
          to="/support"
          textValue="Help Center"
          iconValue={<FiHelpCircle></FiHelpCircle>}
          minimized={sidebarMinimized}
        ></NavItem>
        <NavItem
          to={`mailto:support@vetify.ai?subject=Feedback%20of%20${customClaims?.email}`}
          textValue="Feedback"
          iconValue={<BiEnvelope></BiEnvelope>}
          minimized={sidebarMinimized}
        ></NavItem>
        <div className="accountSection">
          <hr className="m-0 mb-2" />
          <UserProfilePopupButton
            onClickEventSignOut={handleLogout}
            onClickEventPopUp={() => setShowProfilePopup(true)}
            userClaims={customClaims}
            minimized={sidebarMinimized}
          ></UserProfilePopupButton>
        </div>
      </div>

      {showProfilePopup && (
        <UserProfilePopup
          show={showProfilePopup}
          onClose={() => setShowProfilePopup(false)}
          setSidebarMinimized={setSidebarMinimized}
        />
      )}
    </nav>
  );
}

export default HeaderNav;
