import Gender from './Gender';

export class Pet {
  private id: string;
  private name: string;
  private breed: string;
  private clientId: string;
  private gender: Gender;
  private animalType: string;
  private weight: string;
  private age: string;
  private medicalHistory: string;
  private sterilzation: string ;

  constructor(
    id: string,
    name: string,
    breed: string,
    clientId: string,
    gender: Gender,
    animalType: string,
    weight: string,
    age: string,
    medicalHistory: string,
    sterilzation: string,
  ) {
    this.id = id;
    this.name = name;
    this.breed = breed;
    this.clientId = clientId;
    this.gender = gender;
    this.animalType = animalType;
    this.weight = weight;
    this.age = age;
    this.medicalHistory = medicalHistory;
    this.sterilzation = sterilzation;
  }

  public get getId(): string {
    return this.id;
  }

  public get getName(): string {
    return this.name;
  }

  public get getBreed(): string {
    return this.breed;
  }

  public get getClientId(): string {
    return this.clientId;
  }

  public get getGender(): Gender {
    return this.gender;
  }

  public get getAnimalType(): string {
    return this.animalType;
  }

  public get getWeight(): string {
    return this.weight;
  }

  public get getAge(): string {
    return this.age;
  }

  public get getMedicalHistory(): string {
    return this.medicalHistory;
  }
  public get getSterilzation(): string  {
    return this.sterilzation;
  }

  public setId(id: string): void {
    this.id = id;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public setBreed(breed: string): void {
    this.breed = breed;
  }

  public setClientId(clientId: string): void {
    this.clientId = clientId;
  }

  public setGender(gender: Gender): void {
    this.gender = gender;
  }

  public setAnimalType(animalType: string): void {
    this.animalType = animalType;
  }

  public setWeight(weight: string): void {
    this.weight = weight;
  }

  public setAge(age: string): void {
    this.age = age;
  }

  public setMedicalHistory(medicalHistory: string): void {
    this.medicalHistory = medicalHistory;
  }

  public setSterilzation(sterilzation: string): void {
    this.sterilzation = sterilzation;
  }


  toCreatePetJSON(): string {
    const petObject = {
      id: this.id,
      name: this.name,
      breed: this.breed,
      clientId: this.clientId,
      gender: this.gender,
      animalType: this.animalType,
      weight: this.weight,
      age: this.age,
      medicalHistory: this.medicalHistory,
      sterilzation: this.sterilzation,
    };
    return JSON.stringify(petObject);
  }

  public toStringConsultationNotes(): string {
    return `This is the information of the pet { animalType: ${this.animalType}, breed: ${this.breed}, gender: ${this.gender}, 
     weight: ${this.weight}, age: ${this.age} }`;
  }

  public static fromJson(json: any): Pet {
    const jsonObject = JSON.parse(json);

    const pet = new Pet(
      jsonObject['id'],
      jsonObject['name'],
      jsonObject['breed'],
      jsonObject['clientId'],
      jsonObject['gender'],
      jsonObject['animalType'],
      jsonObject['weight'],
      jsonObject['age'],
      jsonObject['medicalHistory'],
      jsonObject['sterilzation'],
    );

    return pet;
  }
}

export default Pet;
