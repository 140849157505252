import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { CustomTooltipMonth } from './CustomTooltipMonth';
import firebase from 'firebase/compat/app';
import './Graph.scss';
import { getDaysInMonth } from '../../../../services/businessLogic/AdminService';
import { DayUsagesMap } from '../../../../models';

interface GraphUsageForMonthProps {
  monthYear: string;
  daysUsage: DayUsagesMap;
  setCurrentDay: any;
}

export const GraphUsageForMonth: React.FC<GraphUsageForMonthProps> = ({
  monthYear,
  daysUsage,
  setCurrentDay,
}) => {
  let chartData: any = [];
  let totalCostData: any = []; // New array for total cost per day

  if (daysUsage) {
    for (let i = 1; i <= getDaysInMonth(monthYear); i++) {
      const dayUsage = daysUsage[i];
      if (dayUsage === undefined) {
        chartData.push({
          x: i,
          y: 0,
        });
        totalCostData.push({
          x: i,
          y: 0,
        });
      } else {
        let countCurrentDayUsages = 0;
        let totalCostForDay = 0; // New variable for total cost per day

        const usagesMapPerHour = dayUsage.usageMapPerHour;
        Object.entries(usagesMapPerHour).map(([hour, hourArrayOfUsages]) => {
          countCurrentDayUsages =
            countCurrentDayUsages + hourArrayOfUsages.length;

          totalCostForDay += hourArrayOfUsages.reduce(
            (acc: number, usage: any) => acc + usage.totalCostUSDDiagnostic,
            0
          );
        });

        chartData.push({
          x: i,
          y: countCurrentDayUsages,
        });

        totalCostData.push({
          x: i,
          y: totalCostForDay,
        });
      }
    }
  } else {
    for (let i = 1; i <= getDaysInMonth(monthYear); i++) {
      chartData.push({
        x: i,
        y: 0,
      });
      totalCostData.push({
        x: i,
        y: 0,
      });
    }
  }

  const [clickedPoint, setClickedPoint] = useState<any>(null);

  return (
    <div className="d-flex justify-content-center mx-2">
      {daysUsage && (
        <div
          className="usagesGraph"
          id="usagesPerDayForSpecificMonth"
          style={{ height: '18rem' }}
        >
          <h3 className="text-secondary text-center">
            Total generated diagnostics for the month <b>{monthYear}</b> -
            divided per day
          </h3>

          <ResponsiveLine
            onClick={(point) => {
              const pointJson = JSON.stringify(point);
              const convertedPoint = JSON.parse(pointJson);
              const foundX = convertedPoint.points[0].data.x;
              setCurrentDay(foundX);
            }}
            data={[
              { id: 'diagnostics', data: chartData },
              { id: 'totalCost', data: totalCostData },
            ]}
            margin={{ top: 10, right: 60, bottom: 100, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: false,
              reverse: false,
            }}
            enableSlices="x"
            yFormat=" >-.1d"
            axisTop={null}
            axisRight={{
              legend: 'Total Cost',
              legendOffset: 50, // Adjust the offset as needed
              legendPosition: 'middle',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisBottom={{
              legend: 'Day',
              legendOffset: 40, // Adjust the offset as needed
              legendPosition: 'middle',
              tickSize: 5, // Adjust the size of the ticks
              tickPadding: 5, // Adjust the padding of the ticks
              tickRotation: 0,
            }}
            curve="monotoneX"
            useMesh={true}
            enableArea={true}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: `Generated diagnostics`,
              legendOffset: -50,
              legendPosition: 'middle',
            }}
            pointSize={6}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            enablePoints={true}
            enableGridX={false}
            enableGridY
            sliceTooltip={(props) => (
              <CustomTooltipMonth {...props} monthYear={monthYear} />
            )}
            colors={{ scheme: 'set3' }}
          />

          {clickedPoint && clickedPoint.data && (
            <div className="clickedPointInfo">
              <h4>Clicked Point Information</h4>
              <p>X Value: {clickedPoint.data.x}</p>
              <p>Y Value: {clickedPoint.data.y}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GraphUsageForMonth;
