import React, { useState } from 'react';
import { HeaderNav, Loader } from '../../common';
import { Consultation, Pet, initializePetAndConsultationFormData } from '../../../models';
import { useNavigate } from 'react-router-dom';
import { MdOutlineAutoGraph } from 'react-icons/md';
import { useData } from '../../../context/DataContext'; // Import your DataContext
import { generateDiagnostic } from '../../../services/API/UserService';
import { ProgressB } from './ProgressBar'
import { StepOneForm, StepTwoForm, StepThreeForm } from './StepForm';
import firebase from 'firebase/compat/app';
import './DiagnoseGeneratorPage.scss';

export const HomePage = () => {
  const user = firebase.auth().currentUser;
  const navigate = useNavigate();
  const { setReturnedConsultation } = useData();
  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(initializePetAndConsultationFormData());

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const createdPet = new Pet(
      '',
      'pet',
      formData.breed,
      '',
      formData.gender,
      formData.animal,
      formData.weight,
      formData.age,
      formData.medicalHistory,
      formData.sterilzation
    );
    const createdConsultation = new Consultation(
      null,
      formData.symptoms?.split(','),
      formData.analyses,
      [],
      createdPet
    );

    try {
      setIsLoading(true);
      const response = await generateDiagnostic(
        await user!.getIdToken(),
        createdConsultation
      );
      if (response.ok) {
        const consultationJson = await response.json();
        const responededConsultation = Consultation.fromJson(consultationJson);
        setRequestError(null);
        setReturnedConsultation(responededConsultation);
        navigate('/' + responededConsultation.getId() + '/result');
      } else {
        console.error('Error:', response.status);
        setRequestError(
          'Response - Something went wrong with the AI: ' + response.status
        );
      }
    } catch (error) {
      console.error('Error:', error);
      setRequestError('Catch - Something went wrong: ' + error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  return (
    <div className="">
      {isLoading ? "" : <HeaderNav />}
      <div className="mx-4">
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <div style={{ height: '40rem' }}>
              <Loader text="Generating interpretation..." />
            </div>
          ) : (
            <div className="form_diagnostic">
              <h1 className="h1 text-center">New Diagnostic <MdOutlineAutoGraph className='text-accent'></MdOutlineAutoGraph></h1>
              <ProgressB
                setActiveStepValue={setStep}
                activeStepValue={(step - 1)}></ProgressB>
              {step === 1 && (
                <StepOneForm
                  formData={formData}
                  setFormData={setFormData}
                  handleChange={handleChange}
                  handleNext={handleNext}
                ></StepOneForm>
              )}
              {step === 2 && (
                <StepTwoForm
                  formData={formData}
                  handleChange={handleChange}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                ></StepTwoForm>
              )}
              {step === 3 && (
                <StepThreeForm
                  formData={formData}
                  setFormData={setFormData}
                  handleChange={handleChange}
                  handlePrev={handlePrev}
                  requestError={requestError}
                  handleFormSubmit={handleFormSubmit}
                ></StepThreeForm>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
