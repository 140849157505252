import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useLocation } from 'react-router-dom';

import {
  AuthPage,
  HomePage,
  ContactPage,
  ResultPage,
  HistoryPage,
  AdminPage,
  OrganizationLinkingPage,
  NotFoundPage,
} from './components/pages';

import { DataProvider } from './context/DataContext';
import { logOut } from './services/API/AuthService';
import './index.scss';
//import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

interface PrivateRouteProps {
  children: React.ReactNode;
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const [authChecked, setAuthChecked] = useState(false);
  const [user, setUser] = useState<firebase.User | null>(null);
  const [customClaims, setCustomClaims] = useState<any>(null);

  const { pathname, hash, key } = useLocation();

  const logOutAndRedirectToLogin = () => {
    logOut();
    return <Navigate to="/auth" />;
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (authUser) => {
      setUser(authUser);
      setAuthChecked(true);
      // Check if the user is authenticated
      if (authUser) {
        try {
          // Get the ID token
          const idTokenResult = await authUser.getIdTokenResult(true);
          const userClaims = idTokenResult.claims;
          setCustomClaims(userClaims);
        } catch (error: any) {
          console.error('Error fetching custom claims:', error.message);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  if (!authChecked) {
    console.log('Waiting for authentication check to complete');
    return null;
  }
  if (!user) {
    console.log('Failed: User is null');
    return logOutAndRedirectToLogin();
  }

  if (pathname !== '/auth' && pathname !== '/link') {
    if (
      customClaims &&
      (customClaims.organizationId === null ||
        customClaims.organizationId === undefined)
    ) {
      console.log('Failed: organizationId is undefined or null');
      return logOutAndRedirectToLogin();
    }
    if (
      customClaims &&
      (customClaims.userId === null || customClaims.userId === undefined)
    ) {
      console.log('Failed: userId is undefined or null');
      return logOutAndRedirectToLogin();
    }
  }
  return <>{children}</>;
}

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <DataProvider>
            <Routes>
              <Route path="/auth" element={<AuthPage />} />
              <Route path="/support" element={<ContactPage />} />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <HomePage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/:consultationId/result"
                element={
                  <PrivateRoute>
                    <ResultPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/link"
                element={
                  <PrivateRoute>
                    <OrganizationLinkingPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/history"
                element={
                  <PrivateRoute>
                    <HistoryPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/organization"
                element={
                  <PrivateRoute>
                    <AdminPage />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </DataProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
